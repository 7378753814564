import React from "react";

import SelectDropdown from "../../select-dropdown/selectDropdown";

export default function DashboardHeader({ gateway, setGateway, gatewayList }) {
	return (
		<div className="header-dashboard">
			<div className="header-dashboard-item">
				<p className="header-dashboard-text">
					Dados referentes a Estação{" "}
				</p>
			</div>

			<div
				className="header-dashboard-item"
				style={{ margin: "-10px 0px 0px 0px" }}
			>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={gatewayList}
					selectedValue={gateway}
					setSelectedValue={setGateway}
					width={"200px"}
				/>
			</div>
		</div>
	);
}
