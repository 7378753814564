import React, { useEffect, useState, useMemo } from "react";
import { useGet } from "../../../../reducers/useGet";

import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { paginationComponentOptions } from "../../../data-table-custom/dataTableCustom";
import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import SelectDataButton from "../../date-selector";

import moment from "moment";

export default function HourlyDataTablePanel({ gateway }) {
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [data, setData] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");
		api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request);

	useEffect(() => {
		if (!getData.state.loading) {
			let convertData = getData.state.data.map((d) => {
				return {
					// Data: moment(d.Data).utcOffset(-6).format("DD-MM-YY HH:mm"),
					Data: d.Data,
					Tmax: d.Tmax ? +d.Tmax : "",
					Tmed: d.Tmed ? +d.Tmed : "",
					Tmin: d.Tmin ? +d.Tmin : "",
					URmax: d.URmax ? +d.URmax : "",
					URmed: d.URmed ? +d.URmed : "",
					URmin: d.URmin ? +d.URmin : "",
					Vmed: d.Vmed ? +d.Vmed : "",
					Pacc: d.Pacc ? +d.Pacc : "",
					PO: d.PO ? +d.PO : "",
					DeltaT: d.DeltaT ? +d.DeltaT : "",
				};
			});

			convertData = convertData.sort(
				(d1, d2) =>
					new Date(d1.Data).getTime() - new Date(d2.Data).getTime()
			);

			setData(convertData);
		}
	}, [getData.state.loading, getData.state.data]);

	const columns = [
		{
			name: "Data",
			selector: (row) =>
				moment(row.Data).utcOffset(-6).format("DD-MM-YY HH:00"),
			sortable: true,
			width: "12%",
		},
		{
			name: "Tmax (ºC)",
			selector: (row) => (row.Tmax !== null ? row.Tmax.toFixed(1) : null),
			omit: data.every((item) => !item.Tmax),
		},
		{
			name: "Tmed (ºC)",
			selector: (row) => (row.Tmed !== null ? row.Tmed.toFixed(1) : null),
			omit: data.every((item) => !item.Tmed),
		},
		{
			name: "Tmin (ºC)",
			selector: (row) => (row.Tmin !== null ? row.Tmin.toFixed(1) : null),
			omit: data.every((item) => !item.Tmin),
		},
		{
			name: "URmax (%)",
			selector: (row) =>
				row.URmax !== null ? row.URmax.toFixed(1) : null,
			omit: data.every((item) => !item.URmax),
		},
		{
			name: "URmed (%)",
			selector: (row) =>
				row.URmed !== null ? row.URmed.toFixed(1) : null,
			omit: data.every((item) => !item.URmed),
		},
		{
			name: "URmin (%)",
			selector: (row) =>
				row.URmin !== null ? row.URmin.toFixed(1) : null,
			omit: data.every((item) => !item.URmin),
		},
		{
			name: "Vmed (km/h)",
			selector: (row) => (row.Vmed !== null ? row.Vmed : null),
			omit: data.every((item) => !item.Vmed),
		},
		{
			name: "Pluviometria (mm)",
			selector: (row) => (row.Pacc !== null ? row.Pacc.toFixed(1) : null),
			omit: data.every((item) => !item.Pacc),
		},
		{
			name: "Ponto de Orvalho (ºC)",
			selector: (row) => (row.PO !== null ? row.PO.toFixed(1) : null),
			omit: data.every((item) => !item.PO),
		},
		{
			name: "DeltaT (ºC)",
			selector: (row) =>
				row.DeltaT !== null ? row.DeltaT.toFixed(1) : null,
			omit: data.every((item) => !item.DeltaT),
		},
	];

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	const actionsMemo = useMemo(
		() => <Export onExport={() => downloadCSV(data, "dados-horarios")} />,
		[data]
	);

	return (
		<div
			className="big-panel-container"
			style={{ margin: "10px 50px 10px 50px" }}
		>
			<div className="panel-title-container">
				<em className="panel-title">Compilado dos Dados por Hora</em>
			</div>

			<div style={{ margin: "10px" }}>
				{getData.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "300px",
						}}
					>
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : (
					<div style={{ margin: "0px 20px 0px 20px" }}>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={true}
						/>
						<DataTable
							columns={columns}
							data={data}
							noDataComponent="Nenhum dado encontrado."
							fixedHeader
							pagination
							paginationComponentOptions={
								paginationComponentOptions
							}
							striped={true}
							actions={actionsMemo}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
