import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import sortArray from "../../../lib/sort-array";

export default function OrganizationList() {
	let organizationsList = useGet("organizacao_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!organizationsList.state.loading) {
			setData(organizationsList.state.data["Organizacao"]);
			setRecords(data);

			setOrganizationsNameList(
				sortArray(organizationsList.state.data["Organizacao"])
			);
		}
	}, [organizationsList.state.loading, organizationsList.state.data, data]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			name: "Representante",
			selector: (row) => {
				let org = organizationsNameList.find(
					(el) => el.id === +row.representante
				);
				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Papel",
			selector: (row) => row.nivel,
		},
	];

	const openOrganizationDetail = (organizationData) => {
		Navigate(`/organizations/${organizationData.id}`, {
			state: {
				organizationData,
				organizationsNameList,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{organizationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<Export
							onExport={() => downloadCSV(data, "organizacoes")}
						/>
						<AddButton
							goTo={"/organizations/add-new-organization"}
							list={organizationsNameList}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Organização encontrada."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(organizationData) =>
							openOrganizationDetail(organizationData)
						}
					/>
				</div>
			)}
		</div>
	);
}
