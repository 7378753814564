import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
// import _ from "lodash";

import { useGet } from "../../../reducers/useGet";

import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { BiCollapseVertical } from "react-icons/bi";
import { MdRouter, MdDeviceHub, MdSettingsRemote } from "react-icons/md";
import "./device-tree-style.css";

export default function DeviceTree() {
	let organizationsList = useGet("organizacao_api");
	let gatewaysList = useGet("gateway_api");
	let dataloggersList = useGet("coletor_api");
	let sensorsList = useGet("sensor_api");
	let irrigationTypeList = useGet("irriga_api");
	let cultivationList = useGet("cultura_api");
	let soilList = useGet("solo_api");
	let deviceModelsList = useGet("modelo_api");

	const Navigate = useNavigate();
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewaysNameList] = useState(null);
	const [dataloggersNameList, setdataloggersNameList] = useState(null);
	const [irrigationNameList, setIrrigationNameList] = useState(null);
	const [cultivationNameList, setCultivationNameList] = useState(null);
	const [soilNameList, setSoilNameList] = useState(null);
	const [deviceModelsNameList, setdeviceModelsNameList] = useState(null);

	const [organizationButton, setOrganizationButton] = useState(false);
	const [gatewayButton, setGatewayButton] = useState(false);
	const [dataloggerButton, setDataloggerButton] = useState(false);

	let handleOrganizationButtonClicked = (id) => {
		setOrganizationButton(organizationButton === id ? false : id);
	};

	let handleGatewayButtonClicked = (id) => {
		setGatewayButton(gatewayButton === id ? false : id);
	};

	let handleDataloggerButtonClicked = (id) => {
		setDataloggerButton(dataloggerButton === id ? false : id);
	};

	useEffect(() => {
		if (
			!dataloggersList.state.loading &&
			!organizationsList.state.loading &&
			!gatewaysList.state.loading &&
			!irrigationTypeList.state.loading &&
			!cultivationList.state.loading &&
			!soilList.state.loading &&
			!sensorsList.state.loading &&
			!deviceModelsList.state.loading
		) {
			let org_names_id = organizationsList.state.data.Organizacao.map(
				(d) => {
					return { id: d.id, nome: d.nome };
				}
			);

			// let gtw_names_id = gatewaysList.state.data.Concentrador.map((d) => {
			// 	return { id: d.id, nome: d.tag };
			// });

			// organizationsList = _.sortBy(
			// 	organizationsList.state.data.Organizacao,
			// 	"nome"
			// );

			let gtw_names_id = gatewaysList.state.data.Concentrador.map((d) => {
				return { id: d.id, nome: d.tag };
			});

			let dl_names_id = dataloggersList.state.data.Coletor.map((d) => {
				return { id: d.id, nome: d.tag };
			});

			let soil_names_id = soilList.state.data.Solos.map((d) => {
				return { id: d.id, nome: d.nome };
			});

			let dm_names_id = deviceModelsList.state.data.Modelos.map((d) => {
				return { id: d.id, nome: d.nome };
			});

			setOrganizationsNameList(org_names_id);
			setGatewaysNameList(gtw_names_id);
			setdataloggersNameList(dl_names_id);
			setIrrigationNameList(irrigationTypeList.state.data.Irrigação);
			setCultivationNameList(cultivationList.state.data.Cultura);
			setSoilNameList(soil_names_id);
			setdeviceModelsNameList(dm_names_id);
		}
	}, [
		dataloggersList.state.loading,
		dataloggersList.state.data.Coletor,
		organizationsList.state.loading,
		organizationsList.state.data.Organizacao,
		gatewaysList.state.loading,
		gatewaysList.state.data.Concentrador,
		irrigationTypeList.state.loading,
		irrigationTypeList.state.data.Irrigação,
		cultivationList.state.loading,
		cultivationList.state.data.Cultura,
		soilList.state.loading,
		soilList.state.data.Solos,
		sensorsList.state.loading,
		deviceModelsList.state.loading,
		deviceModelsList.state.data.Modelos,
	]);

	let ShowGateways = ({ orgID }) => {
		return (
			<div>
				{gatewaysList.state.data.Concentrador.map((d) => (
					<div key={d.id}>
						{d.organi_concentra_id === orgID ? (
							<div>
								<div className="button-container-custom">
									<button
										className="arrow-gateway-button-custom"
										onClick={() =>
											handleGatewayButtonClicked(d.id)
										}
									>
										{gatewayButton === d.id ? (
											<RiArrowDownSLine size={20} />
										) : (
											<RiArrowRightSLine size={20} />
										)}
									</button>
									<button
										className="redirect-button-custom"
										onClick={() =>
											Navigate(`/gateways/${d.id}`, {
												state: {
													gatewayData: d,
													organizationsNameList,
												},
											})
										}
									>
										<MdRouter
											className="device-tree-icon-style"
											size={20}
										/>
										<span className="device-tree-text-style">
											{d.tag}
										</span>
									</button>
								</div>
								{gatewayButton === d.id ? (
									<ShowDLs orgID={orgID} />
								) : null}
							</div>
						) : null}
					</div>
				))}
			</div>
		);
	};

	let ShowDLs = ({ orgID }) => {
		return (
			<div>
				{dataloggersList.state.data.Coletor.map((d) => (
					<div key={d.id}>
						{d.organi_coletor_id === orgID ? (
							<div>
								<div className="button-container-custom">
									<button
										className="arrow-datalogger-button-custom"
										onClick={() =>
											handleDataloggerButtonClicked(d.id)
										}
									>
										{dataloggerButton === d.id ? (
											<RiArrowDownSLine size={20} />
										) : (
											<RiArrowRightSLine size={20} />
										)}
									</button>
									<button
										className="redirect-button-custom"
										onClick={() =>
											Navigate(`/dataloggers/${d.id}`, {
												state: {
													dataloggerData: d,
													organizationsNameList,
													gatewaysNameList,
													irrigationNameList,
													cultivationNameList,
													soilNameList,
												},
											})
										}
									>
										<MdDeviceHub
											className="device-tree-icon-style"
											size={20}
										/>
										<span className="device-tree-text-style">
											{d.tag}
										</span>
									</button>
								</div>
								{dataloggerButton === d.id ? (
									<ShowSensors orgID={orgID} />
								) : null}
							</div>
						) : null}
					</div>
				))}
			</div>
		);
	};

	let ShowSensors = ({ orgID }) => {
		return (
			<div>
				{sensorsList.state.data.Sensores.map((d) => (
					<div key={d.id}>
						{d.organi_sensor_id === orgID ? (
							<div>
								<div className="button-container-custom">
									<button
										className="redirect-sensor-button-custom"
										onClick={() =>
											Navigate(`/sensors/${d.id}`, {
												state: {
													sensorData: d,
													organizationsNameList,
													gatewaysNameList,
													dataloggersNameList,
													deviceModelsNameList,
												},
											})
										}
									>
										<MdSettingsRemote
											className="device-tree-icon-style"
											size={20}
										/>
										<span className="device-tree-text-style">
											{d.tag}
										</span>
									</button>
								</div>
							</div>
						) : null}
					</div>
				))}
			</div>
		);
	};

	const handleCollapseClick = () => {
		setOrganizationButton(false);
		setGatewayButton(false);
		setDataloggerButton(false);
	};

	return (
		<div className="page">
			{dataloggersList.state.loading &&
			organizationsList.state.loading &&
			gatewaysList.state.loading &&
			irrigationTypeList.state.loading &&
			cultivationList.state.loading &&
			soilList.state.loading &&
			sensorsList.state.loading &&
			deviceModelsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="device-tree-container">
					<div className="buttons-container">
						<button
							className="collapse-button"
							onClick={handleCollapseClick}
						>
							<BiCollapseVertical size={16} />
							Recolher
						</button>
					</div>
					{organizationsList.state.data.Organizacao.map((d) => (
						<div key={d.id}>
							<div className="button-container-custom">
								<button
									className="arrow-button-custom"
									onClick={() =>
										handleOrganizationButtonClicked(d.id)
									}
								>
									{organizationButton === d.id ? (
										<RiArrowDownSLine size={20} />
									) : (
										<RiArrowRightSLine size={20} />
									)}
								</button>
								<button
									className="redirect-button-custom"
									onClick={() =>
										Navigate(`/organizations/${d.id}`, {
											state: {
												organizationData: d,
												organizationsNameList,
											},
										})
									}
								>
									<BsFillPeopleFill
										className="device-tree-icon-style"
										size={20}
									/>
									<span className="device-tree-text-style">
										{d.nome}
									</span>
								</button>
							</div>
							{organizationButton === d.id ? (
								<ShowGateways orgID={d.id} />
							) : null}
						</div>
					))}
				</div>
			)}
		</div>
	);
}
