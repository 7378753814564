import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../navbar";
import Dashboard from "../dashboard";
import DeviceTree from "./device-tree/device-tree";

import { GatewayList, GatewayEdit, GatewayCreate } from "./gateways";
import {
	DataloggerList,
	DataloggerEdit,
	DataloggerCreate,
} from "./dataloggers";
import { SensorList, SensorEdit, SensorCreate } from "./sensors";
import {
	ManagementZonesList,
	ManagementZonesEdit,
	ManagementZonesCreate,
} from "./management-zones";
import {
	DeviceModelList,
	DeviceModelEdit,
	DeviceModelCreate,
} from "./sensors-models";
import {
	SensorTypeList,
	SensorTypeEdit,
	SensorTypeCreate,
} from "./sensors-type";
import { UserList, UserEdit, UserCreate } from "./users";
import {
	OrganizationList,
	OrganizationEdit,
	OrganizationCreate,
} from "./organizations";
import {
	IrrigationTypeList,
	IrrigationTypeEdit,
	IrrigationTypeCreate,
} from "./irrigation-type";
import {
	CultivationTypeList,
	CultivationTypeCreate,
	CultivationTypeEdit,
} from "./cultivation-type";
import {
	PhenologicalStagesList,
	PhenologicalStagesEdit,
	PhenologicalStagesCreate,
} from "./cultivation-type/phenological-stages";
import { SoilTypeList, SoilTypeEdit, SoilTypeCreate } from "./soil-type";

function Admin() {
	const [showAllSidebar, setShowAllSidebar] = useState(true);
	const contentStyle = {
		marginLeft: showAllSidebar ? "90px" : "270px",
		transition: "margin 0.2s ease",
	};

	return (
		<div>
			{" "}
			<Navbar
				showAllSidebar={showAllSidebar}
				setShowAllSidebar={setShowAllSidebar}
			/>
			<div style={contentStyle}>
				<Routes>
					<Route path="/dashboard" element={<Dashboard />} exact />
					<Route path="/device-tree" element={<DeviceTree />} />
					<Route path="/gateways" element={<GatewayList />} />
					<Route path="/gateways/:id" element={<GatewayEdit />} />
					<Route
						path="/gateways/add-new-gateway"
						element={<GatewayCreate />}
					/>
					<Route path="/dataloggers" element={<DataloggerList />} />
					<Route
						path="/dataloggers/:id"
						element={<DataloggerEdit />}
					/>
					<Route
						path="/dataloggers/add-new-datalogger"
						element={<DataloggerCreate />}
					/>
					<Route path="/sensors" element={<SensorList />} />
					<Route path="/sensors/:id" element={<SensorEdit />} />
					<Route
						path="/sensors/add-new-sensor"
						element={<SensorCreate />}
					/>
					<Route
						path="/management-zones"
						element={<ManagementZonesList />}
					/>
					<Route
						path="/management-zones/:id"
						element={<ManagementZonesEdit />}
					/>
					<Route
						path="/management-zones/add-new-management-zone"
						element={<ManagementZonesCreate />}
					/>
					<Route
						path="/device-models"
						element={<DeviceModelList />}
					/>
					<Route
						path="/device-models/:id"
						element={<DeviceModelEdit />}
					/>
					<Route
						path="/device-models/add-new-device-model"
						element={<DeviceModelCreate />}
					/>
					<Route path="/sensor-type" element={<SensorTypeList />} />
					<Route
						path="/sensor-type/:id"
						element={<SensorTypeEdit />}
					/>
					<Route
						path="/sensor-type/add-new-sensor-type"
						element={<SensorTypeCreate />}
					/>
					<Route
						path="/organizations"
						element={<OrganizationList />}
					/>
					<Route
						path="/organizations/:id"
						element={<OrganizationEdit />}
					/>
					<Route
						path="/organizations/add-new-organization"
						element={<OrganizationCreate />}
					/>
					<Route path="/users" element={<UserList />} />
					<Route path="/users/:id" element={<UserEdit />} />
					<Route
						path="/users/add-new-user"
						element={<UserCreate />}
					/>
					<Route
						path="/irrigation-type-list"
						element={<IrrigationTypeList />}
					/>
					<Route
						path="/irrigation-type-list/:id"
						element={<IrrigationTypeEdit />}
					/>
					<Route
						path="/irrigation-type-list/add-new-irrigation-type"
						element={<IrrigationTypeCreate />}
					/>
					<Route
						path="/cultivations-list"
						element={<CultivationTypeList />}
					/>
					<Route
						path="/cultivations-list/:id"
						element={<CultivationTypeEdit />}
					/>
					<Route
						path="/cultivations-list/add-new-cultivation"
						element={<CultivationTypeCreate />}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages"
						element={<PhenologicalStagesList />}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages/:id"
						element={<PhenologicalStagesEdit />}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages/add-new-phenological-stage"
						element={<PhenologicalStagesCreate />}
					/>
					<Route path="/soils" element={<SoilTypeList />} />
					<Route path="/soils/:id" element={<SoilTypeEdit />} />
					<Route
						path="/soils/add-new-soil"
						element={<SoilTypeCreate />}
					/>
				</Routes>
			</div>
		</div>
	);
}

export default Admin;
