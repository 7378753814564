import React from "react";
import Plot from "react-plotly.js";
import moment from "moment";

export default function LineChart3Elements({
	data,
	name1,
	name2,
	name3,
	unit,
	color1,
	color2,
	color3,
	fileName,
}) {
	const values = [
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.Tmax),
			name: `${name1}`,
			type: "scatter",
			line: { color: `${color1}` },
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY"
					)}<br><br>Tmax: ${item.Tmax}ºC<br>Tmed: ${
						item.Tmed
					}ºC<br>Tmin: ${item.Tmin}ºC`
			),
			hoverinfo: "text",
		},
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.Tmed),
			name: `${name2}`,
			type: "scatter",
			line: { color: `${color2}` },
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY"
					)}<br><br>Tmax: ${item.Tmax}ºC<br>Tmed: ${
						item.Tmed
					}ºC<br>Tmin: ${item.Tmin}ºC`
			),
			hoverinfo: "text",
		},
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.Tmin),
			name: `${name3}`,
			type: "scatter",
			line: { color: `${color3}` },
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY"
					)}<br><br>Tmax: ${item.Tmax}ºC<br>Tmed: ${
						item.Tmed
					}ºC<br>Tmin: ${item.Tmin}ºC`
			),
			hoverinfo: "text",
		},
	];
	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				xaxis: {
					tickformat: "%d/%m/%y",
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 10, // Margem direita
					t: 40, // Margem superior
					b: 50, // Margem inferior
				},
				hoverlabel: {
					bgcolor: "rgba(51,51,51,255)", // Cor de fundo do tooltip
					font: { color: "white" }, // Cor do texto do tooltip
					align: "left",
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
				showlegend: true,
				legend: {
					orientation: "h",
					xanchor: "center",
					x: 0.5,
					y: 1.2,
				},
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "85%",
				height: "350px",
			}}
		/>
	);
}

// import {
// 	VictoryLine,
// 	VictoryChart,
// 	VictoryAxis,
// 	VictoryTheme,
// 	VictoryLegend,
// 	VictoryScatter,
// } from "victory";
// import moment from "moment";

// export default function LineChart3Elements({
// 	data,
// 	name1,
// 	name2,
// 	name3,
// 	unit,
// 	color1,
// 	color2,
// 	color3,
// }) {
// 	return (
// 		<div>
// 			<VictoryChart
// 				height={250}
// 				theme={VictoryTheme.material}
// 				domainPadding={{ y: 15 }}
// 				padding={{
// 					top: 30,
// 					bottom: 40,
// 					left: 50,
// 					right: 50,
// 				}}
// 			>
// 				<VictoryAxis
// 					style={{
// 						tickLabels: { fontSize: 10 },
// 					}}
// 					tickFormat={(t) => moment(t).format("DD/MM")}
// 					tickCount={4}
// 				/>

// 				<VictoryAxis
// 					dependentAxis
// 					label={`${unit}`}
// 					style={{
// 						axisLabel: { fontSize: 10, padding: 30 },
// 						tickLabels: { fontSize: 10, padding: 2 },
// 					}}
// 					tickFormat={(t) => t.toFixed(1)}
// 				/>

// 				<VictoryLine
// 					data={data}
// 					x="timestamp"
// 					y="Tmax"
// 					style={{ data: { stroke: `${color1}` } }}
// 				/>
// 				<VictoryScatter
// 					data={data}
// 					x="timestamp"
// 					y="Tmax"
// 					size={3}
// 					style={{ data: { fill: `${color1}` } }}
// 				/>

// 				<VictoryLine
// 					data={data}
// 					x="timestamp"
// 					y="Tmed"
// 					style={{ data: { stroke: `${color2}` } }}
// 				/>
// 				<VictoryScatter
// 					data={data}
// 					x="timestamp"
// 					y="Tmed"
// 					size={3}
// 					style={{ data: { fill: `${color2}` } }}
// 				/>

// 				<VictoryLine
// 					data={data}
// 					x="timestamp"
// 					y="Tmin"
// 					style={{ data: { stroke: `${color3}` } }}
// 				/>
// 				<VictoryScatter
// 					data={data}
// 					x="timestamp"
// 					y="Tmin"
// 					size={3}
// 					style={{ data: { fill: `${color3}` } }}
// 				/>

// 				<VictoryLegend
// 					x={95}
// 					orientation="horizontal"
// 					gutter={10}
// 					style={{ border: { stroke: "transparent" } }}
// 					colorScale={[`${color1}`, `${color2}`, `${color3}`]}
// 					data={[
// 						{ name: `${name1}` },
// 						{ name: `${name2}` },
// 						{ name: `${name3}` },
// 					]}
// 				/>
// 			</VictoryChart>
// 		</div>
// 	);
// }
