import React from "react";

import PulverizationConditionsPanel from "../panel/pulverization-conditions-panel/pulverization-conditions-panel";
import DeltaTPanel from "../panel/delta-t-panel/delta-t-panel";

import "../../../styles/dashboard-styles.css";

export default function PulverizationData({ gateway }) {
	return (
		<div>
			<div className="show-data-container">
				<PulverizationConditionsPanel gateway={gateway} />
				<DeltaTPanel gateway={gateway} />
			</div>
		</div>
	);
}
