import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../data-table-custom/add-button";
import SearchBar from "../../data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../data-table-custom/dataTableCustom";
import sortArray from "../../../lib/sort-array";

export default function SensorList() {
	const user = AuthService.getCurrentUser();

	let sensorRequest;
	if (user.nivel.includes("Admin")) sensorRequest = "sensor_api";
	else
		sensorRequest = `sensor_consulta?sensor_organizacao=${user.organizacao}`;

	let sensorsList = useGet(sensorRequest);
	let organizationsList = useGet("organizacao_api");
	let gatewaysList = useGet("gateway_api");
	let dataloggersList = useGet("coletor_api");
	let deviceModelsList = useGet("modelo_api");
	let sensorTypeList = useGet("tipo_sensor_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewaysNameList] = useState(null);
	const [dataloggersNameList, setdataloggersNameList] = useState(null);
	const [deviceModelsNameList, setdeviceModelsNameList] = useState(null);
	const [sensorTypeNameList, setSensorTypeNameList] = useState(null);

	useEffect(() => {
		if (
			!sensorsList.state.loading &&
			!organizationsList.state.loading &&
			!gatewaysList.state.loading &&
			!dataloggersList.state.loading &&
			!deviceModelsList.state.loading &&
			!sensorTypeList.state.loading
		) {
			if (user.nivel.includes("Admin")) {
				setData(sensorsList.state.data["Sensores"]);
			} else {
				setData(sensorsList.state.data);
			}

			setRecords(data);

			let gtw_names_id = gatewaysList.state.data["Concentrador"].map(
				(d) => {
					return { id: d.id, nome: d.tag };
				}
			);

			let dl_names_id = dataloggersList.state.data["Coletor"].map((d) => {
				return { id: d.id, nome: d.tag };
			});

			setOrganizationsNameList(
				sortArray(organizationsList.state.data["Organizacao"])
			);
			setGatewaysNameList(sortArray(gtw_names_id));
			setdataloggersNameList(sortArray(dl_names_id));
			setdeviceModelsNameList(
				sortArray(deviceModelsList.state.data["Modelos"])
			);
			setSensorTypeNameList(
				sortArray(sensorTypeList.state.data["TipoSensor"])
			);
		}
	}, [
		user.nivel,
		sensorsList.state.loading,
		sensorsList.state.data,
		data,
		organizationsList.state.loading,
		organizationsList.state.data,
		gatewaysList.state.loading,
		gatewaysList.state.data,
		dataloggersList.state.loading,
		dataloggersList.state.data,
		deviceModelsList.state.loading,
		deviceModelsList.state.data,
		sensorTypeList.state.loading,
		sensorTypeList.state.data,
	]);

	const columns = [
		{ name: "id", selector: (row) => row.id, sortable: true },
		{ name: "ID", selector: (row) => row.id_dispositivo },
		{ name: "Nome", selector: (row) => row.tag, sortable: true },
		{
			name: "Número de Série",
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsList.state.data["Organizacao"].find(
					(el) => el.id === row.organi_sensor_id
				);

				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Status",
			selector: (row) => (row.status === "on" ? "Ativo" : "Inativo"),
		},
	];

	const openSensorDetail = (sensorData) => {
		Navigate(`/sensors/${sensorData.id}`, {
			state: {
				sensorData,
				organizationsNameList,
				gatewaysNameList,
				dataloggersNameList,
				deviceModelsNameList,
				sensorTypeNameList,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{sensorsList.state.loading &&
			organizationsList.state.loading &&
			gatewaysList.state.loading &&
			dataloggersList.state.loading &&
			deviceModelsList.state.loading &&
			sensorTypeList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"ID"}
						/>
						<Export
							onExport={() => downloadCSV(data, "sensores")}
						/>
						{user.nivel.includes("Admin") ? (
							<AddButton
								goTo={"/sensors/add-new-sensor"}
								list={{
									organizationsNameList,
									gatewaysNameList,
									dataloggersNameList,
									deviceModelsNameList,
									sensorTypeNameList,
								}}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Sensor encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(sensorData) =>
							user.nivel.includes("Admin")
								? openSensorDetail(sensorData)
								: null
						}
					/>
				</div>
			)}
		</div>
	);
}
