import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useGet } from "../../../reducers/useGet";
import AuthService from "../../../services/auth";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import sortArray from "../../../lib/sort-array";

export default function ManagementZonesList() {
	const user = AuthService.getCurrentUser();

	let zmRequest;
	if (user.nivel.includes("Admin")) zmRequest = "zonas_manejo_api";
	else
		zmRequest = `zona_manejo_consulta?zona_manejo_organizacao=${user.organizacao}`;

	let gtwRequest;
	if (user.nivel.includes("Admin")) gtwRequest = "gateway_api";
	else gtwRequest = `gateway_consulta?gateway_organi=${user.organizacao}`;

	let orgRequest;
	if (user.nivel.includes("Admin")) orgRequest = "organizacao_api";
	else orgRequest = `organizacao_consulta?organi_id=${user.organizacao}`;

	let dlRequest;
	if (user.nivel.includes("Admin")) dlRequest = "coletor_api";
	else dlRequest = `coletor_consulta?coletor_organizacao=${user.organizacao}`;

	let getZonasManejo = useGet(zmRequest);
	let getGateways = useGet(gtwRequest);
	let getOrganizations = useGet(orgRequest);
	let getDataloggers = useGet(dlRequest);
	let irrigationTypeList = useGet("irriga_api");
	let cultivationList = useGet("cultura_api");
	let soilList = useGet("solo_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [gatewaysNameList, setGatewaysNameList] = useState(null);
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [dataloggersNameList, setDataloggersNameList] = useState(null);
	const [irrigationNameList, setIrrigationNameList] = useState(null);
	const [cultivationNameList, setCultivationNameList] = useState(null);
	const [soilNameList, setSoilNameList] = useState(null);

	useEffect(() => {
		if (
			!getZonasManejo.state.loading &&
			!getOrganizations.state.loading &&
			!getGateways.state.loading &&
			!getDataloggers.state.loading &&
			!irrigationTypeList.state.loading &&
			!cultivationList.state.loading &&
			!soilList.state.loading &&
			!getGateways.state.error &&
			!getDataloggers.state.error
		) {
			if (user.nivel.includes("Admin")) {
				setData(getZonasManejo.state.data["Zonas de Manejo"]);
				setOrganizationsNameList(
					getOrganizations.state.data["Organizacao"]
				);
			} else {
				setData(getZonasManejo.state.data);
				setOrganizationsNameList(getOrganizations.state.data);
			}

			let gtw_names_id;
			if (!user.nivel.includes("Admin")) {
				gtw_names_id = getGateways.state.data.map((d) => {
					return {
						id: d.id,
						nome: d.tag,
						id_dispositivo: d.id_dispositivo,
					};
				});
			} else {
				gtw_names_id = getGateways.state.data["Concentrador"].map(
					(d) => {
						return {
							id: d.id,
							nome: d.tag,
							id_dispositivo: d.id_dispositivo,
						};
					}
				);
			}

			let org_names_id;
			if (getOrganizations.state.data.length === 1) {
				org_names_id = getOrganizations.state.data.map((d) => {
					return {
						id: d.id,
						nome: d.nome,
					};
				});
			} else {
				org_names_id = getOrganizations.state.data["Organizacao"].map(
					(d) => {
						return {
							id: d.id,
							nome: d.nome,
						};
					}
				);
			}

			let dl_names_id;
			if (!user.nivel.includes("Admin")) {
				dl_names_id = getDataloggers.state.data.map((d) => {
					return {
						id: d.id,
						nome: d.tag,
						id_dispositivo: d.id_dispositivo,
						guid_dispositivo: d.guid_dispositivo,
					};
				});
			} else {
				dl_names_id = getDataloggers.state.data["Coletor"].map((d) => {
					return {
						id: d.id,
						nome: d.tag,
						id_dispositivo: d.id_dispositivo,
						guid_dispositivo: d.guid_dispositivo,
					};
				});
			}

			setRecords(data);
			setGatewaysNameList(sortArray(gtw_names_id));
			setOrganizationsNameList(sortArray(org_names_id));
			setDataloggersNameList(sortArray(dl_names_id));
			setIrrigationNameList(
				sortArray(irrigationTypeList.state.data["Irrigação"])
			);
			setCultivationNameList(
				sortArray(cultivationList.state.data["Cultura"])
			);
			setSoilNameList(sortArray(soilList.state.data["Solos"]));
		}
	}, [
		data,
		user.nivel,
		getZonasManejo.state.loading,
		getZonasManejo.state.data,
		getOrganizations.state.loading,
		getOrganizations.state.data,
		getGateways.state.error,
		getGateways.state.loading,
		getGateways.state.data,
		getDataloggers.state.error,
		getDataloggers.state.loading,
		getDataloggers.state.data,
		irrigationTypeList.state.loading,
		irrigationTypeList.state.data,
		cultivationList.state.loading,
		cultivationList.state.data,
		soilList.state.loading,
		soilList.state.data,
	]);

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
		},
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsNameList.find(
					(el) => el.id === row.organizacao
				);
				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Estação PalmaFlex",
			selector: (row) => {
				let gtw = gatewaysNameList.find(
					(el) => el.id === row.concentrador
				);
				if (gtw === undefined) return "";
				else return gtw.nome;
			},
		},
	];

	const openManagementZoneDetail = (managementZone) => {
		Navigate(`/management-zones/${managementZone.id}`, {
			state: {
				managementZone,
				organizationsNameList,
				gatewaysNameList,
				dataloggersNameList,
				irrigationNameList,
				cultivationNameList,
				soilNameList,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{getZonasManejo.state.loading &&
			getOrganizations.state.loading &&
			getGateways.state.loading &&
			getDataloggers.state.loading &&
			irrigationTypeList.state.loading &&
			cultivationList.state.loading &&
			soilList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"TAG"}
						/>
						<Export
							onExport={() => downloadCSV(data, "zonasManejo")}
						/>
						<AddButton
							goTo={"/management-zones/add-new-management-zone"}
							list={{
								organizationsNameList,
								gatewaysNameList,
								dataloggersNameList,
								irrigationNameList,
								cultivationNameList,
								soilNameList,
							}}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Zona de Manejo encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(zmData) =>
							openManagementZoneDetail(zmData)
						}
					/>
				</div>
			)}
		</div>
	);
}
