import React, { useState } from "react";
import AuthService from "../../services/auth";
import ReactLoading from "react-loading";
import palmaflexLogo from "../../assets/palmaflex-round-logo.png";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

import "./login.css";

function LoginPage() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [hidePassword, setHidePassword] = useState(true);
	const [loading, setLoading] = useState("");
	const [error, setError] = useState("");

	const handleLogin = (e) => {
		e.preventDefault();
		setLoading(true);

		if (!email || !password) {
			setError("E-mail e senha são obrigatórios!");
			setLoading(false);
		} else {
			AuthService.login(email, password).then(
				() => {
					setTimeout(function () {
						window.location = "/dashboard";
					}, 10);
				},
				(error) => {
					setError("E-mail e/ou senha incorreto(s)");
					setLoading(false);
				}
			);
		}
	};

	return (
		<div className="background">
			<div className="container">
				<img
					src={palmaflexLogo}
					alt={"palmaflex-logo"}
					className="palmaflex-logo"
				/>
				<div className="credencials-container">
					<input
						className="login-input"
						type="text"
						placeholder="E-mail"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>

				<div className="credencials-container">
					<input
						className="login-input"
						type={hidePassword ? "password" : "text"}
						placeholder="Senha"
						name="senha"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<button
						className="password-button"
						onClick={() =>
							setHidePassword(hidePassword ? false : true)
						}
					>
						{hidePassword ? (
							<BsEyeSlashFill name="eye-slash" size={16} />
						) : (
							<BsEyeFill name="eye" size={16} />
						)}
					</button>
				</div>
				{error ? (
					<div className="error-container">
						<em>{error}</em>
					</div>
				) : null}
				{loading ? (
					<button className="loading-login">
						<ReactLoading
							type="spin"
							color="white"
							width="20px"
							height="20px"
						/>
					</button>
				) : (
					<button className="buttonText" onClick={handleLogin}>
						Entrar
					</button>
				)}
			</div>
		</div>
	);
}

export default LoginPage;
