import React from "react";
import DailyDataTablePanel from "../panel/daily-data-table-panel";
import TemperatureDataPanel from "../panel/temperature-data-panel/temperature-data-panel";
import PluviometerDataPanel from "../panel/pluviometer-data-panel/pluviometer-data-panel";

import "../../../styles/dashboard-styles.css";

export default function DailyData({ gateway }) {
	return (
		<div>
			<div className="show-data-container">
				<TemperatureDataPanel gateway={gateway} />
				<PluviometerDataPanel gateway={gateway} />
			</div>
			<div className="show-data-big-container">
				<DailyDataTablePanel gateway={gateway} />
			</div>
		</div>
	);
}
