import { useState } from "react";

import SelectDropdown from "../../select-dropdown";
import { deviceModelConversionFunction } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

import "../../../styles/admin-styles.css";

export default function DeviceModelCreate() {
	const [nome, setNome] = useState("");
	const [unidade, setUnidade] = useState("");
	const [conversao, setConversao] = useState(null);

	// Parâmetros da Função a
	const [a6, setA6] = useState("");
	const [a5, setA5] = useState("");
	const [a4, setA4] = useState("");
	const [a3, setA3] = useState("");
	const [a2, setA2] = useState("");
	const [a1, setA1] = useState("");
	const [a0, setA0] = useState("");
	const [amenos1, setAmenos1] = useState("");
	const [amenos2, setAmenos2] = useState("");

	// Parâmetros da Função b
	const [b6, setB6] = useState("");
	const [b5, setB5] = useState("");
	const [b4, setB4] = useState("");
	const [b3, setB3] = useState("");
	const [b2, setB2] = useState("");
	const [b1, setB1] = useState("");
	const [b0, setB0] = useState("");
	const [bmenos1, setBmenos1] = useState("");
	const [bmenos2, setBmenos2] = useState("");

	const [limiteInferior, setLimiteInferior] = useState("");
	const [limiteSuperior, setLimiteSuperior] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Unidade</span>
				<input
					className="admin-input"
					type="text"
					name="unidade"
					value={unidade}
					onChange={(e) => setUnidade(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Função de Conversão</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={deviceModelConversionFunction}
					selectedValue={conversao}
					setSelectedValue={setConversao}
					width={"305px"}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Parâmetros</span>
				<div className="param-container">
					<div>
						{conversao && conversao.id === 4 ? (
							<em className="param-text">
								Polinômio do Numerador
							</em>
						) : null}
					</div>

					<span>&#123;</span>
					<div>
						<span>
							&nbsp; a<sub>6</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a6"
							value={a6}
							onChange={(e) => setA6(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>5</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a5"
							value={a5}
							onChange={(e) => setA5(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>4</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a4"
							value={a4}
							onChange={(e) => setA4(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>3</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a3"
							value={a3}
							onChange={(e) => setA3(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>2</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a2"
							value={a2}
							onChange={(e) => setA2(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>1</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a1"
							value={a1}
							onChange={(e) => setA1(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>0</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="a0"
							value={a0}
							onChange={(e) => setA0(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>-1</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="amenos1"
							value={amenos1}
							onChange={(e) => setAmenos1(e.target.value)}
						/>
						<span>,</span>
					</div>
					<div>
						<span>
							&nbsp; a<sub>-2</sub>: &nbsp;
						</span>
						<input
							className="device-model-params"
							type="number"
							name="amenos2"
							value={amenos2}
							onChange={(e) => setAmenos2(e.target.value)}
						/>
					</div>

					<span>&#125;</span>
				</div>

				{conversao && conversao.id === 4 ? (
					<div className="param-container">
						<div>
							<em className="param-text">
								Polinômio do Denominador
							</em>
						</div>

						<span>&#123;</span>
						<div>
							<span>
								&nbsp; b<sub>6</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b6"
								value={b6}
								onChange={(e) => setB6(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>5</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b5"
								value={b5}
								onChange={(e) => setB5(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>4</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b4"
								value={b4}
								onChange={(e) => setB4(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>3</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b3"
								value={b3}
								onChange={(e) => setB3(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>2</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b2"
								value={b2}
								onChange={(e) => setB2(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>1</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b1"
								value={b1}
								onChange={(e) => setB1(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>0</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="b0"
								value={b0}
								onChange={(e) => setB0(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>-1</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="bmenos1"
								value={bmenos1}
								onChange={(e) => setBmenos1(e.target.value)}
							/>
							<span>,</span>
						</div>
						<div>
							<span>
								&nbsp; b<sub>-2</sub>: &nbsp;
							</span>
							<input
								className="device-model-params"
								type="number"
								name="bmenos2"
								value={bmenos2}
								onChange={(e) => setBmenos2(e.target.value)}
							/>
						</div>

						<span>&#125;</span>
					</div>
				) : null}
			</div>
			<div className="admin-container">
				<span className="admin-title">Limite Inferior</span>
				<input
					className="admin-input"
					type="number"
					name="limite-inferior"
					value={limiteInferior}
					onChange={(e) => setLimiteInferior(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Limite Superior</span>
				<input
					className="admin-input"
					type="number"
					name="limite-superior"
					value={limiteSuperior}
					onChange={(e) => setLimiteSuperior(e.target.value)}
				/>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						nome: nome,
						unidade: unidade,
						conversao: conversao ? conversao.nome : null,
						a6: +a6,
						a5: +a5,
						a4: +a4,
						a3: +a3,
						a2: +a2,
						a1: +a1,
						a0: +a0,
						a_1: +amenos1,
						a_2: +amenos2,
						b6: +b6,
						b5: +b5,
						b4: +b4,
						b3: +b3,
						b2: +b2,
						b1: +b1,
						b0: +b0,
						b_1: +bmenos1,
						b_2: +bmenos2,
						parametros: null,
						inferior: +limiteInferior,
						superior: +limiteSuperior,
					}}
					action={"post"}
					endpoint={`/modelo_api`}
					returnTo={`/device-models`}
				/>
			</div>
		</div>
	);
}
