import React, { useState, useEffect } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import { LineChart1ElementAndArea } from "../../charts";
import moment from "moment";

import "../../../../styles/panel-styles.css";

export default function HydrologicalBalancePanel({ zonaManejo }) {
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [data, setData] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${lastWeek}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request);

	useEffect(() => {
		if (!getData.state.loading) {
			let cadValues = getData.state.data.map((d) => {
				return { timestamp: d.data, value: d.cad };
			});

			cadValues = cadValues.filter((element) => {
				return !isNaN(element.value);
			});
			setData(cadValues);
		}
	}, [getData.state.loading, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Armazenamento de Água no Solo</em>
			</div>

			<div className="panel-content-margin">
				<div>
					{getData.state.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "300px",
							}}
						>
							<ReactLoading
								type="spinningBubbles"
								color="var(--main-color)"
								width="50px"
								height="50px"
							/>
						</div>
					) : (
						<div>
							<SelectDataButton
								periodo1={periodo1}
								setPeriodo1={setPeriodo1}
								periodo2={periodo2}
								setPeriodo2={setPeriodo2}
								hourlyData={false}
							/>
							{data.length !== 0 ? (
								<div className="chart-container">
									<LineChart1ElementAndArea
										data1={data}
										data2={60}
										unit={"%"}
										color1={"#004C99"}
										color2={"red"}
										legend1={"CAD"}
										legend2={"Ponto Crítico"}
										fileName={
											"grafico-armazenamento-agua-no-solo"
										}
									/>
								</div>
							) : (
								<NoDataAvailableMessage />
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
