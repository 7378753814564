import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";

import "../../../styles/admin-styles.css";

export default function DataloggerEdit() {
	const { state } = useLocation();

	// Dados do Coletor
	const [tag, setTag] = useState(`${state.dataloggerData.tag}`);
	const [numeroSerie, setNumeroSerie] = useState(
		`${state.dataloggerData.serie}`
	);
	const [organizacao, setOrganizacao] = useState(
		state.organizationsNameList.find(
			(d) => d.id === state.dataloggerData.organi_coletor_id
		)
	);
	const [gateway, setGateway] = useState(
		state.gatewaysNameList.find(
			(d) => d.id === state.dataloggerData.concentrador
		)
	);
	const [status, setStatus] = useState(
		deviceStatusItems.find(
			(d) =>
				d.nome ===
				(state.dataloggerData.status === "on" ? "Ativo" : "Inativo")
		)
	);
	const [modeloDatalogger, setModeloDatalogger] = useState(
		`${state.dataloggerData.modelo}`
	);
	const [firmware, setFirmware] = useState(
		`${state.dataloggerData.firmware}`
	);
	const [hardware, setHardware] = useState(
		`${state.dataloggerData.hardware}`
	);
	const [latitude, setLatitude] = useState(`${state.dataloggerData.lat}`);
	const [longitude, setLongitude] = useState(`${state.dataloggerData.lon}`);
	const [altitude, setAltitude] = useState(`${state.dataloggerData.elev}`);

	return (
		<div className="page">
			<div>
				<div className="admin-container">
					<span className="admin-title">ID *</span>
					<input
						className="admin-input-disabled"
						type="text"
						name="id"
						value={`${state.dataloggerData.id_dispositivo}`}
						readOnly
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Tag *</span>
					<input
						className="admin-input"
						type="text"
						name="tag"
						value={tag}
						onChange={(e) => setTag(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<span className="admin-title">Número de Série *</span>
					<input
						className="admin-input"
						type="text"
						name="numeroserie"
						value={numeroSerie}
						onChange={(e) => setNumeroSerie(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Organização</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.organizationsNameList}
						selectedValue={organizacao}
						setSelectedValue={setOrganizacao}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Concentrador</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.gatewaysNameList}
						selectedValue={gateway}
						setSelectedValue={setGateway}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Status</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={deviceStatusItems}
						selectedValue={status}
						setSelectedValue={setStatus}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Sobre o Coletor</span>
				</div>

				<div className="admin-container">
					<em className="item-text">Modelo</em>
					<input
						className="admin-input"
						type="text"
						name="modelo"
						value={modeloDatalogger}
						onChange={(e) => setModeloDatalogger(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Versão do Firmware</em>
					<input
						className="admin-input"
						type="text"
						name="firmware"
						value={firmware}
						onChange={(e) => setFirmware(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Versão do Hardware</em>
					<input
						className="admin-input"
						type="text"
						name="hardware"
						value={hardware}
						onChange={(e) => setHardware(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Geolocalização</span>
				</div>
				<div className="admin-container">
					<em className="item-text">Latitude</em>
					<input
						className="admin-input"
						type="text"
						name="latitude"
						value={latitude}
						onChange={(e) => setLatitude(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<em className="item-text">Longitude</em>
					<input
						className="admin-input"
						type="text"
						name="longitude"
						value={longitude}
						onChange={(e) => setLongitude(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<em className="item-text">Altitude (m)</em>
					<input
						className="admin-input"
						type="text"
						name="altitude"
						value={altitude}
						onChange={(e) => setAltitude(e.target.value)}
					/>
				</div>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						id: `${state.dataloggerData.id}`,
						organi_coletor_id: organizacao
							? organizacao.id
							: state.dataloggerData.organi_coletor_id,
						id_dispositivo: `${state.dataloggerData.id_dispositivo}`,
						guid_dispositivo: `${state.dataloggerData.guid_dispositivo}`,
						tag: tag,
						serie: numeroSerie,
						modelo: modeloDatalogger,
						status: status && status.nome === "Ativo" ? "on" : null,
						concentrador: gateway ? gateway.id : "",
						firmware: firmware,
						hardware: hardware,
						lat: latitude,
						lon: longitude,
						elev: altitude,
					}}
					action={"put"}
					endpoint={`/coletor_api/${state.dataloggerData.id}`}
					returnTo={"/dataloggers"}
				/>
				<DeleteDataButton
					endpoint={`/coletor_api/${state.dataloggerData.id}`}
					returnTo={"/dataloggers"}
				/>
			</div>
		</div>
	);
}
