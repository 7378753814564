import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import sortArray from "../../../lib/sort-array";

export default function GatewayList() {
	const user = AuthService.getCurrentUser();

	let gtwRequest;
	if (user.nivel.includes("Admin")) gtwRequest = "gateway_api";
	else gtwRequest = `gateway_consulta?gateway_organi=${user.organizacao}`;

	let gatewaysList = useGet(gtwRequest);
	let organizationsList = useGet("organizacao_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);

	useEffect(() => {
		if (!gatewaysList.state.loading && !organizationsList.state.loading) {
			if (user.nivel.includes("Admin")) {
				setData(gatewaysList.state.data["Concentrador"]);
			} else {
				setData(gatewaysList.state.data);
			}

			setRecords(data);

			setOrganizationsNameList(
				sortArray(organizationsList.state.data["Organizacao"])
			);
		}
	}, [
		user.nivel,
		gatewaysList.state.loading,
		gatewaysList.state.data,
		data,
		organizationsList.state.loading,
		organizationsList.state.data,
	]);

	const columns = [
		{ name: "id", selector: (row) => row.id, sortable: true },
		{
			name: "ID",
			selector: (row) => row.id_dispositivo,
		},
		{ name: "Tag", selector: (row) => row.tag, sortable: true },
		{
			name: "Número de Série",
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsList.state.data["Organizacao"].find(
					(el) => el.id === row.organi_concentra_id
				);
				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Status",
			selector: (row) => (row.status === "on" ? "Ativo" : "Inativo"),
		},
	];

	const openGatewayDetail = (gatewayData) => {
		Navigate(`/gateways/${gatewayData.id}`, {
			state: {
				gatewayData,
				organizationsNameList,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{gatewaysList.state.loading && organizationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"TAG"}
						/>
						<Export
							onExport={() => downloadCSV(data, "concentradores")}
						/>
						{user.nivel.includes("Admin") ? (
							<AddButton
								goTo={"/gateways/add-new-gateway"}
								list={organizationsNameList}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Concentrador encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(gatewayData) =>
							user.nivel.includes("Admin")
								? openGatewayDetail(gatewayData)
								: null
						}
					/>
				</div>
			)}
		</div>
	);
}
