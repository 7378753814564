import "./dataTableCustom.css";
import { BiSearchAlt2 } from "react-icons/bi";

export default function SearchBar({ records, setRecords, data, keyword }) {
	function handleFilter(event) {
		const newData = data.filter((row) => {
			switch (keyword) {
				case "NAME":
					return row.nome
						.toLowerCase()
						.includes(event.target.value.toLowerCase());

				case "TAG":
					return row.tag
						.toLowerCase()
						.includes(event.target.value.toLowerCase());

				case "ID":
					return row.id_dispositivo
						.toLowerCase()
						.includes(event.target.value.toLowerCase());

				default:
					return null;
			}
		});

		setRecords(newData);
	}

	return (
		<div className="search-bar-container">
			<BiSearchAlt2 size={"20px"} className="search-icon" />
			<input
				type="text"
				placeholder="Pesquisar"
				onChange={handleFilter}
				className="search-bar-custom"
			/>
		</div>
	);
}
