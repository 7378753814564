import React, { useState, useEffect } from "react";
import { useGet } from "../../reducers/useGet";
import ReactLoading from "react-loading";

import AuthService from "../../services/auth";

import DashboardHeader from "./dashboard-header";
import DashboardButtons from "./dashboard-buttons";
import sortArray from "../../lib/sort-array";

import HourlyData from "./hourly-data";
import DailyData from "./daily-data";
import HydrologicalBalanceData from "./hydrological-balance-data";
import PulverizacaoData from "./pulverization-data";
import "../../styles/dashboard-styles.css";

export default function Dashboard() {
	const { organizacao, nivel } = AuthService.getCurrentUser();

	let endpoint_gtw;
	if (nivel.includes("Admin")) endpoint_gtw = "gateway_api";
	else endpoint_gtw = `gateway_consulta?gateway_organi=${organizacao}`;

	let getGateway = useGet(endpoint_gtw);

	const [gateway, setGateway] = useState(null);
	const [gatewayList, setGatewayList] = useState([]);
	// Tipos de Botão:
	// 1 - horario
	// 2 - diario
	// 3 - Balanço Hídrico
	// 4 - Pulverização
	const [button1, setButton1] = useState(false);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);
	const [button4, setButton4] = useState(false);

	useEffect(() => {
		if (!getGateway.state.loading) {
			let gtw_names_id;
			if (getGateway.state.data.length === 1) {
				gtw_names_id = getGateway.state.data.map((d) => {
					return {
						id: d.id,
						nome: d.tag,
						id_dispositivo: d.id_dispositivo,
					};
				});
			} else {
				gtw_names_id = getGateway.state.data["Concentrador"].map(
					(d) => {
						return {
							id: d.id,
							nome: d.tag,
							id_dispositivo: d.id_dispositivo,
						};
					}
				);
			}

			setGatewayList(sortArray(gtw_names_id));
		}
	}, [getGateway.state.data, getGateway.state.loading]);

	return (
		<div className="page">
			{getGateway.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div>
					<DashboardHeader
						gateway={gateway}
						setGateway={setGateway}
						gatewayList={gatewayList}
					/>

					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"horario"}
							buttonNumber={button1}
							setButtonNumber={setButton1}
							buttonTitle={"Dados Climáticos Horários"}
							gateway={gateway}
						/>
						{button1 ? <HourlyData gateway={gateway} /> : null}
					</div>

					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"diario"}
							buttonNumber={button2}
							setButtonNumber={setButton2}
							buttonTitle={"Dados Climáticos Diários"}
							gateway={gateway}
						/>
						{button2 ? <DailyData gateway={gateway} /> : null}
					</div>

					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"balanco-hidrico"}
							buttonNumber={button3}
							setButtonNumber={setButton3}
							buttonTitle={"Balanço Hídrico"}
							gateway={gateway}
						/>
						{button3 ? (
							<HydrologicalBalanceData gateway={gateway} />
						) : null}
					</div>

					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"pulverizacao"}
							buttonNumber={button4}
							setButtonNumber={setButton4}
							buttonTitle={"Pulverização"}
							gateway={gateway}
						/>
						{button4 ? (
							<PulverizacaoData gateway={gateway} />
						) : null}
					</div>
				</div>
			)}
		</div>
	);
}
