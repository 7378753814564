import React, { useEffect, useState } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import moment from "moment";

import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";

import thermometerIcon from "../../../../assets/icons/thermometer-icon.png";
import humidityIcon from "../../../../assets/icons/humidity-icon.png";
import rainDropsIcon from "../../../../assets/icons/rain-drops-icon.png";
import windIcon from "../../../../assets/icons/wind-icon.png";
import dewPointIcon from "../../../../assets/icons/dew-point-icon.png";

import "../../../../styles/panel-styles.css";

export default function LastDataPanel({ gateway }) {
	const [data, setData] = useState([]);

	let getData = useGet(
		`dados_horarios_consulta?gateway=${
			gateway.id_dispositivo
		}&ultimo_dado=${1}`
	);

	useEffect(() => {
		if (!getData.state.loading && getData.state.data.length > 0) {
			setData({
				Data: getData.state.data[0].Data,
				PO: getData.state.data[0].PO ? +getData.state.data[0].PO : NaN,
				Pacc: getData.state.data[0].Pacc
					? +getData.state.data[0].Pacc
					: NaN,
				Tmed: getData.state.data[0].Tmed
					? +getData.state.data[0].Tmed
					: NaN,
				URmed: getData.state.data[0].URmed
					? +getData.state.data[0].URmed
					: NaN,
				Vmed: getData.state.data[0].Vmed
					? +getData.state.data[0].Vmed
					: NaN,
			});
		}
	}, [getData.state.loading, getData.state.data]);

	return (
		<div className="little-panel-container" style={{ height: "400px" }}>
			<div className="panel-title-container">
				<em className="panel-title">Condições Climáticas Atuais</em>
			</div>

			<div className="panel-content-margin">
				<div>
					{getData.state.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "300px",
							}}
						>
							<ReactLoading
								type="spinningBubbles"
								color="var(--main-color)"
								width="50px"
								height="50px"
							/>
						</div>
					) : data.length !== 0 ? (
						<div>
							<div className="weather-data-container">
								<img
									src={thermometerIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Temperatura do Ar: &nbsp;
								</p>
								{!isNaN(data.Tmed) ? (
									<p className="last-value-text">
										{data.Tmed.toFixed(1)} ºC
									</p>
								) : (
									<p className="last-value-text">-- ºC</p>
								)}
							</div>

							<div className="weather-data-container">
								<img
									src={humidityIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Umidade Relativa do Ar: &nbsp;
								</p>
								{!isNaN(data.URmed) ? (
									<p className="last-value-text">
										{data.URmed.toFixed(1)} %
									</p>
								) : (
									<p className="last-value-text">-- %</p>
								)}
							</div>

							<div className="weather-data-container">
								<img
									src={windIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Velocidade do Vento: &nbsp;
								</p>
								{!isNaN(data.Vmed) ? (
									<p className="last-value-text">
										{data.Vmed.toFixed(1)} km/h
									</p>
								) : (
									<p className="last-value-text">-- km/h</p>
								)}
							</div>

							<div className="weather-data-container">
								<img
									src={rainDropsIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Pluviometria: &nbsp;
								</p>

								{!isNaN(data.Pacc) ? (
									<p className="last-value-text">
										{" "}
										{data.Pacc.toFixed(1)} mm
									</p>
								) : (
									<p className="last-value-text">-- mm</p>
								)}
							</div>
							<div className="weather-data-container">
								<img
									src={dewPointIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Ponto de Orvalho: &nbsp;
								</p>

								{!isNaN(data.PO) ? (
									<p className="last-value-text">
										{" "}
										{data.PO.toFixed(1)} ºC
									</p>
								) : (
									<p className="last-value-text">-- ºC</p>
								)}
							</div>

							<div className="panel-footer-container">
								<p className="panel-footer-text">
									Dados atualizados em{" "}
									{moment(data.Data)
										.utcOffset(-6)
										.format("DD-MM-YY HH:mm")}
								</p>
							</div>
						</div>
					) : (
						<NoDataAvailableMessage />
					)}
				</div>
			</div>
		</div>
	);
}
