import React, { useEffect, useState } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import SelectDataButton from "../../date-selector";
import { LineChart3Elements } from "../../charts";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message/no-data-available-message";

import moment from "moment";

import "../../../../styles/panel-styles.css";

export default function TemperatureDataPanel({ gateway }) {
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [tempData, setTempData] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request);

	useEffect(() => {
		let data = [];
		if (!getData.state.loading) {
			data = getData.state.data.map((d) => {
				if (+d.Tmax !== 0) {
					return {
						timestamp: moment(d.data).format("YYYY-MM-DD"),
						Tmax: +d.Tmax,
						Tmin: +d.Tmin,
						Tmed: +d.Tmed,
					};
				}
				return null;
			});

			data = data.filter((elemento) => elemento !== null);

			data = data.sort(
				(d1, d2) =>
					new Date(d1.timestamp).getTime() -
					new Date(d2.timestamp).getTime()
			);
		}
		setTempData(data);
	}, [getData.state.loading, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">
					Temperaturas Máximas, Mínimas e Médias por Dia
				</em>
			</div>
			<div>
				{getData.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "300px",
						}}
					>
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : (
					<div>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={false}
						/>
						{tempData.length > 0 ? (
							<div className="chart-container">
								<LineChart3Elements
									data={tempData}
									name1={"Tmáx"}
									name2={"Tméd"}
									name3={"Tmin"}
									unit={"ºC"}
									color1={"#CC0000"}
									color2={"#004C99"}
									color3={"#FF8000"}
									fileName={"grafico-temperaturas-diarias"}
								/>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
