import { useState } from "react";
import { useLocation } from "react-router-dom";

import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";

import "../../../styles/admin-styles.css";

export default function CultivationTypeEdit() {
	const { state } = useLocation();

	const [nome, setNome] = useState(`${state.cultivationData.nome}`);

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={`${state.cultivationData.id}`}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						id: `${state.cultivationData.id}`,
						nome: `${nome}`,
					}}
					action={"put"}
					endpoint={`/cultura_api/${state.cultivationData.id}`}
					returnTo={"/cultivations-list"}
				/>
				<DeleteDataButton
					endpoint={`/cultura_api/${state.cultivationData.id}`}
					returnTo={"/cultivations-list"}
				/>
			</div>
		</div>
	);
}
