import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import sortArray from "../../../lib/sort-array";

export default function DataloggerList() {
	const user = AuthService.getCurrentUser();

	let dlRequest;
	if (user.nivel.includes("Admin")) dlRequest = "coletor_api";
	else dlRequest = `coletor_consulta?coletor_organizacao=${user.organizacao}`;

	let dataloggersList = useGet(dlRequest);
	let organizationsList = useGet("organizacao_api");
	let gatewaysList = useGet("gateway_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewaysNameList] = useState(null);

	useEffect(() => {
		if (
			!dataloggersList.state.loading &&
			!organizationsList.state.loading &&
			!gatewaysList.state.loading
		) {
			if (user.nivel.includes("Admin")) {
				setData(dataloggersList.state.data["Coletor"]);
			} else {
				setData(dataloggersList.state.data);
			}

			setRecords(data);

			let gtw_names_id = gatewaysList.state.data["Concentrador"].map(
				(d) => {
					return { id: d.id, nome: d.tag };
				}
			);

			setOrganizationsNameList(
				sortArray(organizationsList.state.data["Organizacao"])
			);
			setGatewaysNameList(sortArray(gtw_names_id));
		}
	}, [
		user.nivel,
		dataloggersList.state.loading,
		dataloggersList.state.data,
		data,
		organizationsList.state.loading,
		organizationsList.state.data,
		gatewaysList.state.loading,
		gatewaysList.state.data,
	]);

	const columns = [
		{ name: "id", selector: (row) => row.id, sortable: true },
		{
			name: "ID",
			selector: (row) => row.id_dispositivo,
		},
		{ name: "Tag", selector: (row) => row.tag, sortable: true },
		{
			name: "Número de Série",
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsNameList.find(
					(el) => el.id === row.organi_coletor_id
				);

				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Concentrador",
			selector: (row) => {
				let gtw = gatewaysNameList.find(
					(el) => el.id === row.concentrador
				);
				if (gtw === undefined) return "";
				else return gtw.nome;
			},
		},
		{
			name: "Status",
			selector: (row) => (row.status === "on" ? "Ativo" : "Inativo"),
		},
	];

	const openDataloggerDetail = (dataloggerData) => {
		Navigate(`/dataloggers/${dataloggerData.id}`, {
			state: {
				dataloggerData,
				organizationsNameList,
				gatewaysNameList,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{dataloggersList.state.loading &&
			organizationsList.state.loading &&
			gatewaysList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"TAG"}
						/>
						<Export
							onExport={() => downloadCSV(data, "coletores")}
						/>
						{user.nivel.includes("Admin") ? (
							<AddButton
								goTo={"/dataloggers/add-new-datalogger"}
								list={{
									organizationsNameList,
									gatewaysNameList,
								}}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Coletor encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(dataloggerData) =>
							user.nivel.includes("Admin")
								? openDataloggerDetail(dataloggerData)
								: null
						}
					/>
				</div>
			)}
		</div>
	);
}
