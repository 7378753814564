import React, { useEffect, useState } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import { AreaChartDeltaT } from "../../charts";

import "../../../../styles/panel-styles.css";

export default function DeltaTPanel({ gateway }) {
	const [data, setData] = useState([]);

	let getData = useGet(
		`dados_horarios_consulta?gateway=${
			gateway.id_dispositivo
		}&ultimo_dado=${1}`
	);

	useEffect(() => {
		if (!getData.state.loading && getData.state.data.length > 0) {
			setData({
				Data: getData.state.data[0].Data,
				Tmed: getData.state.data[0].Tmed
					? +getData.state.data[0].Tmed
					: NaN,
				URmed: getData.state.data[0].URmed
					? +getData.state.data[0].URmed
					: NaN,
				DeltaT: getData.state.data[0].DeltaT
					? +getData.state.data[0].DeltaT
					: NaN,
			});
		}
	}, [getData.state.loading, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Delta T</em>
			</div>
			<div>
				{getData.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "300px",
						}}
					>
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : (
					<div>
						{getData.state.data.length > 0 ? (
							<AreaChartDeltaT
								data={data}
								fileName={"grafico-delta-t"}
							/>
						) : (
							<NoDataAvailableMessage />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
