import React, { useState, useEffect } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import moment from "moment/moment";

import AvailableWaterPanel from "./available-water-panel";
import IrrigationTimePanel from "./irrigation-time-panel";
import PercentimeterPanel from "./percentimeter-panel/percentimeter-panel";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";

import "../../../../styles/panel-styles.css";

export default function AlertsPanel({ zonaManejo }) {
	const [data, setData] = useState([]);
	const [irrigationType, setIrrigationType] = useState("");

	let getIrrigationType = useGet(`irriga_api/${zonaManejo.dado_irriga}`);

	let getZMData = useGet(
		`dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&ultimo_dado=1`
	);

	useEffect(() => {
		if (
			!getIrrigationType.state.loading &&
			!getZMData.state.loading &&
			!getIrrigationType.state.error &&
			getZMData.state.data.length > 0
		) {
			setIrrigationType(getIrrigationType.state.data.nome);
			setData(getZMData.state.data[0]);
		}
	}, [
		getIrrigationType.state.loading,
		getIrrigationType.state.data,
		getZMData.state.data,
		getZMData.state.loading,
		getIrrigationType.state.error,
	]);

	return (
		<div
			className="little-panel-container"
			style={{
				height:
					data.ad20 !== null && data.ad60 !== null
						? "500px"
						: "355px",
			}}
		>
			<div className="panel-title-container">
				<em className="panel-title">Alertas</em>
			</div>

			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{getIrrigationType.state.loading &&
					getZMData.state.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "300px",
							}}
						>
							<ReactLoading
								type="spinningBubbles"
								color="var(--main-color)"
								width="50px"
								height="50px"
							/>
						</div>
					) : data.ad20 !== null && data.ad60 !== null ? (
						<div>
							<AvailableWaterPanel
								data={data}
								zonaManejo={zonaManejo}
							/>
							{irrigationType ? (
								<div>
									<div>
										{(irrigationType.includes(
											"Gotejamento"
										) ||
											irrigationType.includes(
												"Aspersão"
											)) &&
										data.ti !== null ? (
											<IrrigationTimePanel data={data} />
										) : null}
									</div>
									<div>
										{irrigationType.includes("Pivô") &&
										data.r_perc !== null ? (
											<PercentimeterPanel data={data} />
										) : null}
									</div>
								</div>
							) : null}
						</div>
					) : (
						<NoDataAvailableMessage />
					)}
					<div className="panel-footer-container">
						{data.ad20 !== null && data.ad60 !== null ? (
							<p className="panel-footer-text">
								Dados atualizados em{" "}
								{moment(data.data).format("DD/MM/YYYY")}
							</p>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
