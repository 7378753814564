import pivotIcon from "../../../../../assets/icons/pivot-percentimeter-icon.png";
import "../../../../../styles/panel-styles.css";

export default function PercentimeterPanel({ data }) {
	let panelText = "";

	if (data.r_perc > 100) panelText = "Não Irrigar!";
	else panelText = data.r_perc.toFixed(0) + "%";

	return (
		<div
			className="alerts-container"
			style={{ width: "270px", height: "110px" }}
		>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">
					Regulagem do Percentímetro
				</span>
			</div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<img
					src={pivotIcon}
					height={"65px"}
					alt=""
					style={{ margin: "15px 5px 0px 15px" }}
				/>

				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "center",
					}}
				>
					{isNaN(data.r_perc) ? (
						<span
							style={{
								fontWeight: "bold",
								fontSize: "20px",
								textAlign: "center",
							}}
						>
							Nenhum dado disponível
						</span>
					) : (
						<span
							style={{
								fontWeight: "bold",
								fontSize: "24px",
								textAlign: "center",
							}}
						>
							{panelText}
						</span>
					)}
				</div>
			</div>
		</div>
	);
}
