import React, { useEffect, useState } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message/no-data-available-message";

import { LineChart2Elements2Axis } from "../../charts";
import moment from "moment";

import "../../../../styles/panel-styles.css";

export default function THDataPanel({ gateway }) {
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [tempArray, setTempArray] = useState([]);
	const [urArray, setURArray] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");
		api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_horarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request);

	useEffect(() => {
		let tArray = [];
		let rhArray = [];
		if (!getData.state.loading) {
			tArray = getData.state.data.map((d) => {
				return { timestamp: d.Data, value: +d.Tmed };
			});
			rhArray = getData.state.data.map((d) => {
				return { timestamp: d.Data, value: +d.URmed };
			});
		}

		tArray = tArray.sort(
			(d1, d2) =>
				new Date(d1.timestamp).getTime() -
				new Date(d2.timestamp).getTime()
		);

		rhArray = rhArray.sort(
			(d1, d2) =>
				new Date(d1.timestamp).getTime() -
				new Date(d2.timestamp).getTime()
		);
		setTempArray(tArray);
		setURArray(rhArray);
	}, [getData.state.loading, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">
					Gráfico de Temperatura e Umidade Relativa
				</em>
			</div>
			<div>
				{getData.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "300px",
						}}
					>
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : (
					<div>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={true}
						/>

						{tempArray.length > 0 && urArray.length > 0 ? (
							<div className="chart-container">
								<LineChart2Elements2Axis
									data1={tempArray}
									data2={urArray}
									name1={"Temperatura"}
									name2={"Umidade Relativa"}
									unit1={"ºC"}
									unit2={"%"}
									color1={"#FF8000"}
									color2={"#004C99"}
									fileName={
										"grafico-temperatura-e-umidade-relativa"
									}
								/>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
