import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useLocation, useParams } from "react-router-dom";
import { useGet } from "../../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../data-table-custom/add-button";
import EditItemButton from "../../../data-table-custom/edit-item-button";
import DeleteItemButton from "../../../data-table-custom/delete-item-button";
import { paginationComponentOptions } from "../../../data-table-custom/dataTableCustom";

export default function PhenologicalStagesList() {
	const { state } = useLocation();
	const routeParams = useParams();

	let phenologicalStagesList = useGet("/fenologica_api");

	const [data, setData] = useState([]);
	const [cultivationId, setCultivationId] = useState("");

	useEffect(() => {
		if (state == null) {
			setCultivationId(+routeParams.id);
		} else {
			setCultivationId(state.data.id);
		}

		if (!phenologicalStagesList.state.loading) {
			setData(phenologicalStagesList.state.data["Fenológica"]);
		}
	}, [
		phenologicalStagesList.state.loading,
		phenologicalStagesList.state.data,
		data,
		routeParams.id,
		state,
	]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{ name: "Ciclo (dias)", selector: (row) => row.ciclo, sortable: true },
		{
			name: "Profundidade da Raiz (cm)",
			selector: (row) => row.profundidade,
		},
		{ name: "kc", selector: (row) => row.kc },
		{
			cell: (row) => (
				<EditItemButton
					data={row}
					goTo={`/cultivations-list/${row.cultura_feno_id}/phenological-stages/${row.id}`}
				/>
			),
		},
		{
			cell: (row) => (
				<DeleteItemButton
					endpoint={`/fenologica_api/${row.id}`}
					returnTo={0} // Refresh page
				/>
			),
		},
	];

	return (
		<div className="page">
			{phenologicalStagesList.state.loading || !cultivationId ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<AddButton
							goTo={`/cultivations-list/${cultivationId}/phenological-stages/add-new-phenological-stage`}
							list={cultivationId}
						/>
					</div>
					<DataTable
						columns={columns}
						data={data.filter(
							(f) => f.cultura_feno_id === cultivationId
						)}
						noDataComponent="Nenhuma Fase Fenológica encontrada."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
					/>
				</div>
			)}
		</div>
	);
}
