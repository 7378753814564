import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import { BiCalendar } from "react-icons/bi";
import { BsCalendar4Event, BsCalendar3Week } from "react-icons/bs";

import "./date-selector-styles.css";
import "react-datepicker/dist/react-datepicker.css";

export default function DateSelector({
	periodo1,
	setPeriodo1,
	periodo2,
	setPeriodo2,
	hourlyData,
}) {
	registerLocale("pt-BR", ptBR); // Registre a localização para pt-BR
	setDefaultLocale("pt-BR"); // Configure pt-BR como o idioma padrão
	const [openDiv, setOpenDiv] = useState(false);
	const [checkbox1, setCheckbox1] = useState(true);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [checkbox4, setCheckbox4] = useState(false);

	const [getDate1, setGetDate1] = useState(new Date(periodo1)); // Para não ficar atualizando...
	const [getDate2, setGetDate2] = useState(""); // ... os dados quando for alterando a data de busca
	const [lastDays, setLastDays] = useState("");
	const [lastHours, setLastHours] = useState("");

	useEffect(() => {
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	const refDiv = useRef(null); // Para detectar se o usuário clicou fora do div date-selector

	const hideOnClickOutside = (e) => {
		if (refDiv.current && !refDiv.current.contains(e.target)) {
			setOpenDiv(false);
		}
	};

	const handleCheckbox1Change = () => {
		setCheckbox1(true);
		setGetDate1(new Date());
		setCheckbox2(false);
		setCheckbox3(false);
		setCheckbox4(false);
		setLastDays("");
		setLastHours("");
	};

	const handleCheckbox2Change = () => {
		setCheckbox1(false);
		setCheckbox2(true);
		setCheckbox3(false);
		setCheckbox4(false);
		setGetDate1(new Date());
		setGetDate2("");
		setLastHours("");
	};

	const handleCheckbox3Change = () => {
		setCheckbox1(false);
		setCheckbox2(false);
		setCheckbox3(true);
		setCheckbox4(false);
		setGetDate1(new Date());
		setGetDate2("");
		setLastDays("");
	};

	const handleCheckbox4Change = () => {
		if (checkbox1) {
			setCheckbox2(false);
			setCheckbox3(false);
			setCheckbox4(!checkbox4);
			setGetDate2(new Date());
			setLastDays("");
		}

		if (checkbox4) setGetDate2("");
	};

	const confirmDataButton = () => {
		if (checkbox1 && !isNaN(getDate1)) {
			setPeriodo1(new Date(getDate1));
		} else if (checkbox2 && lastDays) {
			let last = new Date();
			last.setDate(last.getDate() - lastDays);
			setPeriodo1(new Date(last));
			setGetDate1(new Date(last));
		} else if (checkbox3 && lastHours) {
			let last = new Date();
			last.setHours(last.getHours() - lastHours);
			setPeriodo1(new Date(last));
			setGetDate1(new Date(last));
		}

		if (checkbox4) {
			setPeriodo2(new Date(getDate2));
		} else {
			setPeriodo2("");
		}

		setOpenDiv(false);
	};

	let period2;
	if (periodo2) {
		if (hourlyData) {
			period2 = `até ${moment(getDate2)
				.utcOffset(-3)
				.format("DD/MM/YY HH:mm")}`;
		} else {
			period2 = `até ${moment(getDate2).format("DD/MM/YY")}`;
		}
	} else {
		period2 = "";
	}

	return (
		<div className="date-selector-container">
			<div className="div-flex">
				<button
					className="select-period-button"
					onClick={() => setOpenDiv(!openDiv)}
				>
					<BiCalendar
						size={"20px"}
						style={{
							marginRight: "10px",
						}}
					/>
					{hourlyData
						? `${moment(getDate1).format(
								"DD/MM/YY HH:mm"
						  )} ${period2}`
						: `${moment(getDate1).format("DD/MM/YY")} ${period2}`}
				</button>
			</div>
			<div ref={refDiv}>
				{openDiv ? (
					<div className="overlay">
						<div className="popup">
							<div className="div-flex">
								<input
									className="checkbox-style"
									style={{ marginTop: "10px" }}
									type="checkbox"
									checked={checkbox1}
									onChange={handleCheckbox1Change}
								/>
								<div
									style={{
										width: "100%",
									}}
								>
									<div className="div-flex">
										<span className="date-type-title">
											Data Inicial:
										</span>
										{hourlyData ? (
											<div className="date-container">
												<DatePicker
													className="input-date"
													selected={getDate1}
													onChange={(date) =>
														setGetDate1(date)
													}
													showTimeSelect
													timeFormat="p"
													timeIntervals={60}
													dateFormat="Pp"
													disabled={!checkbox1}
												/>
												<BsCalendar4Event
													size={14}
													style={{
														color: "rgb(7, 39, 26)",
													}}
												/>
											</div>
										) : (
											<div className="date-container">
												<DatePicker
													className="input-date"
													selected={getDate1}
													onChange={(date) =>
														setGetDate1(date)
													}
													dateFormat="P"
													disabled={!checkbox1}
												/>
												<BsCalendar4Event
													size={14}
													style={{
														color: "rgb(7, 39, 26)",
													}}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="div-flex">
								<input
									className="checkbox-style"
									style={{
										marginTop: "10px",
									}}
									type="checkbox"
									checked={checkbox4}
									onChange={handleCheckbox4Change}
								/>
								<div
									style={{
										width: "100%",
									}}
								>
									<div className="div-flex">
										<span
											className="date-type-title"
											style={{ marginRight: "17px" }}
										>
											Data Final:
										</span>
										{hourlyData ? (
											<div className="date-container">
												<DatePicker
													className="input-date"
													selected={getDate2}
													onChange={(date) =>
														setGetDate2(date)
													}
													showTimeSelect
													timeFormat="p"
													timeIntervals={60}
													dateFormat="Pp"
													disabled={!checkbox4}
												/>
												<BsCalendar3Week
													size={14}
													style={{
														color: "rgb(7, 39, 26)",
													}}
												/>
											</div>
										) : (
											<div className="date-container">
												<DatePicker
													className="input-date"
													selected={getDate2}
													onChange={(date) =>
														setGetDate2(date)
													}
													dateFormat="P"
													disabled={!checkbox4}
												/>
												<BsCalendar3Week
													size={14}
													style={{
														color: "rgb(7, 39, 26)",
													}}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="div-border" />
							<div className="div-flex">
								<input
									className="checkbox-style"
									style={{ marginTop: "10px" }}
									type="checkbox"
									checked={checkbox2}
									onChange={handleCheckbox2Change}
								/>
								<div
									style={{
										width: "100%",
									}}
								>
									<div className="div-flex">
										<span className="date-type-title">
											Último(s) Dia(s):
										</span>
										<input
											className="input-period"
											style={{ width: "55%" }}
											type="number"
											min={1}
											name="ultimos-dias"
											value={lastDays}
											onChange={(e) =>
												setLastDays(e.target.value)
											}
											disabled={!checkbox2}
										/>
									</div>
								</div>
							</div>
							{hourlyData ? (
								<div>
									<div className="div-border" />
									<div className="div-flex">
										<input
											className="checkbox-style"
											style={{ marginTop: "10px" }}
											type="checkbox"
											checked={checkbox3}
											onChange={handleCheckbox3Change}
										/>
										<div
											style={{
												width: "100%",
											}}
										>
											<div className="div-flex">
												<span className="date-type-title">
													Última(s) Hora(s):
												</span>
												<input
													className="input-period"
													style={{ width: "52%" }}
													type="number"
													name="ultimas-horas"
													min={1}
													value={lastHours}
													onChange={(e) =>
														setLastHours(
															e.target.value
														)
													}
													disabled={!checkbox3}
												/>
											</div>
										</div>
									</div>
								</div>
							) : null}
							<button
								className="select-data-button"
								onClick={confirmDataButton}
							>
								CONFIRMAR
							</button>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
