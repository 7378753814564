import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
	useLocation,
} from "react-router-dom";

import AuthService from "./services/auth";
import Login from "./components/login";
import Admin from "./components/admin";

function App() {
	const user = AuthService.getCurrentUser();

	let PrivateRoutes = () => {
		const location = useLocation();

		if (user === undefined) return null;

		return user ? (
			<Outlet />
		) : (
			<Navigate to="/" replace state={{ from: location }} />
		);
	};

	return (
		<Router>
			<Routes>
				<Route element={<PrivateRoutes />}>
					<Route path="*" element={<Admin />} />
				</Route>
				<Route
					// path="/"
					path="/acesso"
					element={user ? <Navigate to="/dashboard" /> : <Login />}
				/>
			</Routes>
		</Router>
	);
}

export default App;
