export const statusItems = [
	{ id: 1, nome: "Desabilitado" },
	{ id: 2, nome: "Habilitado" },
];

export const roleItems = [
	{ id: 1, nome: "Somente Visualização" },
	{ id: 2, nome: "Cliente" },
	{ id: 3, nome: "Representante" },
	{ id: 4, nome: "Administrador do Sistema" },
];

export const deviceStatusItems = [
	{ id: 1, nome: "Inativo" },
	{ id: 2, nome: "Ativo" },
];

export const deviceModelConversionFunction = [
	{ id: 1, nome: "Polinomial" },
	{ id: 2, nome: "Diferencial" },
	{ id: 3, nome: "Janela Móvel" },
	{ id: 4, nome: "Divisão de Polinômios" },
];

export const soilDataType = [
	{ id: 1, nome: "Curva de Retenção" },
	{ id: 2, nome: "Granulometria" },
];

export const soilSensorType = [
	{ id: 1, nome: "Tensiômetro" },
	{ id: 2, nome: "Volumétrico" },
];

export const estimatedWaterStorageItems = [
	{ id: 1, nome: "Muito Úmido", porcentagem: 0.9 },
	{ id: 2, nome: "Úmido", porcentagem: 0.7 },
	{ id: 3, nome: "Médio", porcentagem: 0.5 },
	{ id: 4, nome: "Seco", porcentagem: 0.3 },
	{ id: 5, nome: "Muito Seco", porcentagem: 0.1 },
];
