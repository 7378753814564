import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { roleItems, statusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

import "../../../styles/admin-styles.css";

export default function UserCreate() {
	const { state } = useLocation();

	const [email, setEmail] = useState("");
	const [nome, setNome] = useState("");
	const [status, setStatus] = useState(null);
	const [papel, setPapel] = useState(null);
	const [organizacao, setOrganizacao] = useState(null);
	const [checkbox1, setCheckbox1] = useState(false); // Notificação por Inatividade
	const [checkbox2, setCheckbox2] = useState(false); // Notificação de alertas de valores fora do normal
	const [checkbox3, setCheckbox3] = useState(false); // Notificação de alarmes de valores críticos
	const [atualizacaoAutomatica, setAtualizacaoAutomatica] = useState(1);
	const [senha, setSenha] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">E-mail *</span>
				<input
					className="admin-input"
					type="text"
					name="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome *</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Senha *</span>
				<input
					className="admin-input"
					type="text"
					name="senha"
					value={senha}
					onChange={(e) => setSenha(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Status</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={statusItems}
					selectedValue={status}
					setSelectedValue={setStatus}
					width={"305px"}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Papel</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={roleItems}
					selectedValue={papel}
					setSelectedValue={setPapel}
					width={"305px"}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Organização</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list}
					selectedValue={organizacao}
					setSelectedValue={setOrganizacao}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Notificações</span>
			</div>
			<div className="admin-container">
				<div className="checkbox-container">
					<input
						className="checkbox-style"
						type="checkbox"
						checked={checkbox1}
						onChange={(e) => setCheckbox1(e.target.checked)}
					/>
					<em className="checkbox-text">
						Notificação por Inatividade
					</em>
				</div>
			</div>

			<div className="admin-container">
				<div className="checkbox-container">
					<input
						className="checkbox-style"
						type="checkbox"
						checked={checkbox2}
						onChange={(e) => setCheckbox2(e.target.checked)}
					/>
					<em className="checkbox-text">
						Alerta de Valores Fora do Normal
					</em>
				</div>
			</div>

			<div className="admin-container">
				<div className="checkbox-container">
					<input
						className="checkbox-style"
						type="checkbox"
						checked={checkbox3}
						onChange={(e) => setCheckbox3(e.target.checked)}
					/>
					<em className="checkbox-text">
						Alarme de Valores Críticos
					</em>
				</div>
			</div>

			<div className="admin-container">
				<span className="admin-title">
					Intervalo de Atualização Automática (minutos)
				</span>
				<input
					className="admin-input"
					type="number"
					name="atualizacaoAutomatica"
					value={atualizacaoAutomatica}
					onChange={(e) => setAtualizacaoAutomatica(e.target.value)}
					min={1}
				/>
			</div>

			<div className="footer">
				<SaveDataButton
					data={{
						organi_user_id: `${
							organizacao ? organizacao.id : null
						}`,
						email: `${email}`,
						nome: `${nome}`,
						intervalo_auto: `${atualizacaoAutomatica}`,
						notifica_inatividade: `${checkbox1 ? "on" : null}`,
						alerta_valores: `${checkbox3 ? "on" : null}`,
						valores_fora: `${checkbox2 ? "on" : null}`,
						status:
							status && status.nome === "Habilitado"
								? "on"
								: null,
						nivel: `${papel ? papel.nome : null}`,
						senha: senha,
					}}
					action={"post"}
					endpoint={`/user_api`}
					returnTo={`/users`}
				/>
			</div>
		</div>
	);
}
