import React from "react";
import palmaflexName from "../../assets/palmaflex.png";
import AuthService from "../../services/auth";

import { FiLogOut } from "react-icons/fi";
import {
	MdOutlineDashboard,
	MdOutlineRouter,
	MdOutlineDeviceHub,
	MdOutlineCalculate,
	MdOutlineSettingsRemote,
} from "react-icons/md";
import { FaSeedling } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import { VscOrganization } from "react-icons/vsc";
import { AiOutlineUser } from "react-icons/ai";
import { GiWateringCan } from "react-icons/gi";
import { MdLandscape } from "react-icons/md";
import { TbMap2 } from "react-icons/tb";
import { FaTape } from "react-icons/fa";

import "./navbar.css";

function Navbar({ showAllSidebar, setShowAllSidebar }) {
	const user = AuthService.getCurrentUser();

	return (
		<div>
			<nav className="navbar">
				<button
					id="toggle"
					className="list-button"
					onClick={(event) => setShowAllSidebar(!showAllSidebar)}
				>
					<BsList size={"2em"} color={"white"} />
				</button>
				<img
					src={palmaflexName}
					alt={"name-palmaflex"}
					className="navbar-image"
				/>
			</nav>
			<nav
				className={`sidebar-container ${
					showAllSidebar ? "showAllSidebar" : ""
				}`}
			>
				<div className="menu-bar">
					<ul className="menu-links">
						<li title="Dashboard" className="nav-link">
							<a href="/dashboard">
								<MdOutlineDashboard
									className="icon"
									size={"1.5em"}
								/>
								<span className="text nav-text">Dashboard</span>
							</a>
						</li>
						{/* <li title="Árvore de Dispositivos" className="nav-link">
							<a href="/device-tree">
								<FaRegListAlt className="icon" size={"1.5em"} />
								<span className="text nav-text">
									Árvore de Dispositivos
								</span>
							</a>
						</li> */}
						<li title="Concentradores" className="nav-link">
							<a href="/gateways">
								<MdOutlineRouter
									className="icon"
									size={"1.5em"}
								/>
								<span className="text nav-text">
									Concentradores
								</span>
							</a>
						</li>
						<li title="Coletores" className="nav-link">
							<a href="/dataloggers">
								<MdOutlineDeviceHub
									className="icon"
									size={"1.5em"}
								/>
								<span className="text nav-text">Coletores</span>
							</a>
						</li>
						<li title="Sensores" className="nav-link">
							<a href="/sensors">
								<MdOutlineSettingsRemote
									className="icon"
									size={"1.5em"}
								/>
								<span className="text nav-text">Sensores</span>
							</a>
						</li>
						<li title="Zonas de Manejo" className="nav-link">
							<a href="/management-zones">
								<TbMap2 className="icon" size={"1.5em"} />
								<span className="text nav-text">
									Zonas de Manejo
								</span>
							</a>
						</li>
						{user.nivel.includes("Admin") ? (
							<li title="Modelos" className="nav-link">
								<a href="/device-models">
									<MdOutlineCalculate
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Modelos
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Tipos de Sensor" className="nav-link">
								<a href="/sensor-type">
									<FaTape className="icon" size={"1.3em"} />
									<span className="text nav-text">
										Tipos de Sensor
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Usuários" className="nav-link">
								<a href="/users">
									<AiOutlineUser
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Usuários
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Organizações" className="nav-link">
								<a href="/organizations">
									<VscOrganization
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Organizações
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Tipos de Irrigação" className="nav-link">
								<a href="/irrigation-type-list">
									<GiWateringCan
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Tipos de Irrigação
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Culturas" className="nav-link">
								<a href="/cultivations-list">
									<FaSeedling
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Culturas
									</span>
								</a>
							</li>
						) : null}
						{user.nivel.includes("Admin") ? (
							<li title="Tipos de Solos" className="nav-link">
								<a href="/soils">
									<MdLandscape
										className="icon"
										size={"1.5em"}
									/>
									<span className="text nav-text">
										Tipos de Solos
									</span>
								</a>
							</li>
						) : null}
						<li className="nav-link">
							<a
								title="Sair"
								href="/acesso"
								// href="/login"
								onClick={() => AuthService.logout()}
							>
								<FiLogOut className="icon" size={"1.5em"} />
								<span className="text nav-text">Sair</span>
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default Navbar;
