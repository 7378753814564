import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";

import "../../../styles/admin-styles.css";

export default function SensorEdit() {
	const { state } = useLocation();

	const [tag, setTag] = useState(`${state.sensorData.tag}`);
	const [organizacao, setOrganizacao] = useState(
		state.organizationsNameList.find(
			(d) => d.id === state.sensorData.organi_sensor_id
		)
	);
	const [gateway, setGateway] = useState(
		state.gatewaysNameList.find(
			(d) => d.id === state.sensorData.concentrador
		)
	);
	const [datalogger, setDatalogger] = useState(
		state.dataloggersNameList.find((d) => d.id === state.sensorData.coletor)
	);
	const [numeroSerie, setNumeroSerie] = useState(`${state.sensorData.serie}`);
	const [status, setStatus] = useState(
		deviceStatusItems.find(
			(d) =>
				d.nome ===
				(state.sensorData.status === "on" ? "Ativo" : "Inativo")
		)
	);
	const [tipo, setTipo] = useState(
		state.sensorTypeNameList.find((d) => d.id === state.sensorData.tipo)
	);
	const [modelo, setModelo] = useState(
		state.deviceModelsNameList.find(
			(d) => d.id === +state.sensorData.modelo
		)
	);
	const [correcao, setCorrecao] = useState(`${state.sensorData.correcao}`);
	const [alertaInferior, setAlertaInferior] = useState(
		`${state.sensorData.alerta_inferior}`
	);
	const [alertaSuperior, setAlertaSuperior] = useState(
		`${state.sensorData.alerta_superior}`
	);
	const [alarmeInferior, setAlarmeInferior] = useState(
		`${state.sensorData.alarme_inferior}`
	);
	const [alarmeSuperior, setAlarmeSuperior] = useState(
		`${state.sensorData.alarme_superior}`
	);

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="guid"
					value={`${state.sensorData.id_dispositivo}`}
					readOnly
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Tag *</span>
				<input
					className="admin-input"
					type="text"
					name="tag"
					value={tag}
					onChange={(e) => setTag(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Número de Série *</span>
				<input
					className="admin-input"
					type="text"
					name="numeroserie"
					value={numeroSerie}
					onChange={(e) => setNumeroSerie(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Organização</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.organizationsNameList}
					selectedValue={organizacao}
					setSelectedValue={setOrganizacao}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Concentrador</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.gatewaysNameList}
					selectedValue={gateway}
					setSelectedValue={setGateway}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Coletor</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.dataloggersNameList}
					selectedValue={datalogger}
					setSelectedValue={setDatalogger}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Status</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={deviceStatusItems}
					selectedValue={status}
					setSelectedValue={setStatus}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Sobre o Sensor</span>
			</div>
			<div className="admin-container">
				<em className="item-text">Tipo de Sensor</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.sensorTypeNameList}
					selectedValue={tipo}
					setSelectedValue={setTipo}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Modelo</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.deviceModelsNameList}
					selectedValue={modelo}
					setSelectedValue={setModelo}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Correção</em>
				<input
					className="admin-input"
					type="text"
					name="correcao"
					value={correcao}
					onChange={(e) => setCorrecao(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Definição de Valores</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Alerta Inferior</em>
				<input
					className="admin-input"
					type="text"
					name="alerta-inferior"
					value={alertaInferior}
					onChange={(e) => setAlertaInferior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alerta Superior</em>
				<input
					className="admin-input"
					type="text"
					name="alerta-superior"
					value={alertaSuperior}
					onChange={(e) => setAlertaSuperior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alarme Inferior</em>
				<input
					className="admin-input"
					type="text"
					name="alarme-inferior"
					value={alarmeInferior}
					onChange={(e) => setAlarmeInferior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alarme Superior</em>
				<input
					className="admin-input"
					type="text"
					name="alarme-superior"
					value={alarmeSuperior}
					onChange={(e) => setAlarmeSuperior(e.target.value)}
				/>
			</div>

			<div className="footer">
				<SaveDataButton
					data={{
						id: `${state.sensorData.id}`,
						organi_sensor_id: organizacao
							? organizacao.id
							: state.sensorData.organizacao,
						id_dispositivo: `${state.sensorData.id_dispositivo}`,
						tag: tag,
						tipo: tipo ? tipo.id : state.sensorData.tipo,
						serie: +numeroSerie,
						modelo: modelo ? modelo.id : state.sensorData.modelos,
						concentrador: gateway
							? gateway.id
							: state.sensorData.concentrador,
						coletor: datalogger
							? datalogger.id
							: state.sensorData.coletor,
						status: status.nome === "Ativo" ? "on" : null,
						alerta_inferior: +alertaInferior,
						alerta_superior: +alertaSuperior,
						alarme_inferior: +alarmeInferior,
						alarme_superior: +alarmeSuperior,
						correcao: +correcao,
					}}
					action={"put"}
					endpoint={`/sensor_api/${state.sensorData.id}`}
					returnTo={"/sensors"}
				/>
				<DeleteDataButton
					endpoint={`/sensor_api/${state.sensorData.id}`}
					returnTo={"/sensors"}
				/>
			</div>
		</div>
	);
}
