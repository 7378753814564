import React, { useState, useEffect } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import _ from "lodash";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message/no-data-available-message";
import { LineChart1ElementAndBarChart2Elements } from "../../charts";
import moment from "moment";

import "../../../../styles/panel-styles.css";

export default function HydrologicalBalancePanel({ zonaManejo, gateway }) {
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [data, setData] = useState([]);

	let api_request_dados_diarios, api_request_dados_zm;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request_dados_diarios = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		api_request_dados_zm = `dados_zona_manejo_consulta?id_zm=${
			zonaManejo.id
		}&data:>=${moment(lastWeek).format("YYYY-MM-DD")}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request_dados_diarios = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
			api_request_dados_zm = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request_dados_diarios = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
			api_request_dados_zm = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request_dados_diarios);
	let getZMData = useGet(api_request_dados_zm);

	useEffect(() => {
		if (
			!getData.state.loading &&
			!getZMData.state.loading &&
			getData.state.data.length > 0 &&
			getZMData.state.data.length > 0
		) {
			let pluvData = getData.state.data.map((d) => {
				let p = +d.Pacc;
				return {
					timestamp: moment(d.data).format("YYYY-MM-DD"),
					PLUV: p.toFixed(1),
				};
			});

			pluvData = pluvData.sort(
				(d1, d2) =>
					new Date(d1.timestamp).getTime() -
					new Date(d2.timestamp).getTime()
			);

			let lbData = getZMData.state.data.map((d) => {
				return { timestamp: d.data, LB: d.lb };
			});

			let etcData = getZMData.state.data.map((d) => {
				return { timestamp: d.data, ETC: d.etc };
			});

			let union = _(lbData)
				.concat(etcData)
				.groupBy("timestamp")
				.reject({ length: 0 })
				.map(_.spread(_.merge))
				.value();

			union = _(union)
				.concat(pluvData)
				.groupBy("timestamp")
				.reject({ length: 0 })
				.map(_.spread(_.merge))
				.value();

			union = union.filter((item) => item.PLUV !== undefined);

			union = union.filter((item) => !isNaN(item.LB) || !isNaN(item.ETC));

			union = union.map((d) => {
				return {
					PLUV: +d.PLUV,
					LB: +d.LB,
					ETC: +d.ETC,
					timestamp: d.timestamp,
				};
			});

			setData(union);
		}
	}, [
		getData.state.loading,
		getData.state.data,
		getZMData.state.loading,
		getZMData.state.data,
	]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Balanço Hídrico</em>
			</div>

			<div className="panel-content-margin">
				<div>
					{getData.state.loading && getZMData.state.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "300px",
							}}
						>
							<ReactLoading
								type="spinningBubbles"
								color="var(--main-color)"
								width="50px"
								height="50px"
							/>
						</div>
					) : (
						<div>
							<SelectDataButton
								periodo1={periodo1}
								setPeriodo1={setPeriodo1}
								periodo2={periodo2}
								setPeriodo2={setPeriodo2}
								hourlyData={false}
							/>
							{data.length > 0 ? (
								<div className="chart-container">
									<LineChart1ElementAndBarChart2Elements
										data={data}
										name1={"Pluviometria"}
										name2={"LB"}
										name3={"ETc"}
										unit={"mm"}
										color1={"#304199"}
										color2={"#43A132"}
										color3={"#ED1E24"}
										fileName={"grafico-balanco-hidrico"}
									/>
								</div>
							) : (
								<NoDataAvailableMessage />
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
