import React from "react";

import SelectDropdown from "../../select-dropdown";
import ZMIcon from "../../../assets/icons/management-zones-icon.png";

export default function ManagementZonesHeader({
	listaZonaManejo,
	zonaManejo,
	setZonaManejo,
}) {
	return (
		<div className="header-dashboard">
			<div
				className="header-dashboard-item"
				style={{ alignItems: "center", display: "flex" }}
			>
				<img
					src={ZMIcon}
					height={"30px"}
					alt=""
					style={{ marginRight: "5px" }}
				/>
				{/* <span className="header-dashboard-text"> */}
				<span style={{ fontSize: "12px", fontWeight: "bold" }}>
					Dados referentes à Zona de Manejo{" "}
				</span>
			</div>

			<div
				className="header-dashboard-item"
				style={{ margin: "-10px 0px 0px 0px" }}
			>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={listaZonaManejo}
					selectedValue={zonaManejo}
					setSelectedValue={setZonaManejo}
					width={"200px"}
				/>
			</div>
		</div>
	);
}
