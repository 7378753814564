import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import {
	soilDataType,
	estimatedWaterStorageItems,
	soilSensorType,
} from "../items";

import SelectDropdown from "../../select-dropdown";
import SaveDataButton from "../../data-table-custom/save-data-button";

import { TabNavItem, TabContent } from "../../tab-component/tab-component";

import "../../../styles/admin-styles.css";

export default function ManagementZonesEdit() {
	const user = AuthService.getCurrentUser();
	const { state } = useLocation();

	const [activeTab, setActiveTab] = useState("tab1");

	let getZMData = useGet(
		`dados_zona_manejo_consulta?id_zm=${state.managementZone.id}&ultimo_dado=1`
	);

	// Aparecerá a 5ª Tab, se tiver dados da ZM no BD
	const [dadosZM, setDadosZM] = useState([]);

	// Sobre a Zona de Manejo
	const [nome, setNome] = useState(`${state.managementZone.nome}`);
	const [gateway, setGateway] = useState(
		state.gatewaysNameList.find(
			(d) => d.id === state.managementZone.concentrador
		)
	);
	const [organizacao, setOrganizacao] = useState(
		state.organizationsNameList.find(
			(d) => d.id === state.managementZone.organizacao
		)
	);
	const [coletor, setColetor] = useState(
		state.dataloggersNameList.find(
			(d) => d.id === state.managementZone.coletor
		)
	);

	let org_no_admin;
	if (!user.nivel.includes("Admin")) org_no_admin = user.organizacao;

	// Dados de Irrigação
	const [sistemaIrrigacao, setSistemaIrrigacao] = useState(
		state.irrigationNameList.find(
			(d) => d.id === state.managementZone.dado_irriga
		)
	);
	const [eficienciaSistema, setEficienciaSistema] = useState(
		`${state.managementZone.efici_sistema}`
	);
	const [vazaoEmissor, setVazaoEmissor] = useState(
		`${state.managementZone.vazao_emissor}`
	);
	const [espacamentoEntreLinhas, setEspacamentoEntreLinhas] = useState(
		`${state.managementZone.espa_linha}`
	);
	const [espacamentoEntreEmissores, setEspacamentoEntreEmissores] = useState(
		`${state.managementZone.espa_emissores}`
	);
	const [projecaoSaia, setProjecaoSaia] = useState(
		`${state.managementZone.proje_saia}`
	);
	const [vazaoPivo, setVazaoPivo] = useState(
		`${state.managementZone.vazao_sistema_pivo}`
	);
	const [areaPivo, setAreaPivo] = useState(
		`${state.managementZone.area_pivo}`
	);
	const [tempoGiroPivo, setTempoGiroPivo] = useState(
		`${state.managementZone.tempo_zero_cem}`
	);

	// Dados da Cultura e Solo
	const [cultura, setCultura] = useState(
		state.cultivationNameList.find(
			(d) => d.id === state.managementZone.cultura
		)
	);
	const [dataPlantio, setDataPlantio] = useState(
		`${state.managementZone.data_cultura}`
	);
	const [espacamentoLinhasCultura, setEspacamentoLinhasCultura] = useState(
		`${state.managementZone.espa_linha_cultura}`
	);
	const [espacamentoEntrePlantas, setEspacamentoEntrePlantas] = useState(
		`${state.managementZone.espa_planta_cultura}`
	);
	const [sensorSolo, setSensorSolo] = useState(
		`${state.managementZone.sensor_solo}` === "on" ? true : false
	);
	const [tipoSensorSolo, setTipoSensorSolo] = useState(
		soilSensorType !== "Nulo"
			? soilSensorType.find((d) =>
					d.nome.includes(state.managementZone.tipo_sensor_solo)
			  )
			: null
	);
	const [tipoDadoSolo, setTipoDadoSolo] = useState(
		soilDataType.find((d) =>
			d.nome.includes(state.managementZone.tipo_dado_solo)
		)
	);
	// Curva de Retenção
	const [solo, setSolo] = useState(
		state.soilNameList.find(
			(d) => d.id === state.managementZone.id_solo_curva
		)
	);
	// Granulometria
	const [argila, setArgila] = useState(`${state.managementZone.argila}`);
	const [areia, setAreia] = useState(`${state.managementZone.areia}`);
	const [silte, setSilte] = useState(`${state.managementZone.silte}`);
	const [materiaOrganica, setMateriaOrganica] = useState(
		`${state.managementZone.materia_organica}`
	);
	// CC e PMP em kPa => dependem do tensiômetro
	const [cc, setCC] = useState(`${state.managementZone.cc}`);
	const [pmp, setPMP] = useState(`${state.managementZone.pmp}`);
	//Capacidade de Campo em %
	const [thetaS0a20, setThetaS0a20] = useState(
		`${state.managementZone.theta_s_0_20}`
	);
	const [thetaS20a40, setThetaS20a40] = useState(
		`${state.managementZone.theta_s_20_40}`
	);
	const [thetaS40a60, setThetaS40a60] = useState(
		`${state.managementZone.theta_s_40_60}`
	);
	//Armazenamento Crítico %
	const [thetaR0a20, setThetaR0a20] = useState(
		`${state.managementZone.theta_r_0_20}`
	);
	const [thetaR20a40, setThetaR20a40] = useState(
		`${state.managementZone.theta_r_20_40}`
	);
	const [thetaR40a60, setThetaR40a60] = useState(
		`${state.managementZone.theta_r_40_60}`
	);
	const [armHidricoEstimado, setArmHidricoEstimado] = useState(
		estimatedWaterStorageItems.find(
			(d) => d.porcentagem === state.managementZone.arm_hidrico_estimado
		)
	);

	const [dataARMHidricoEstimado, setDataARMHidricoEstimado] = useState(
		state.managementZone.data_arm_hidrico_estimado
	);

	// Definição de Alertas
	const [adOtima20, setADOtima20] = useState(
		`${state.managementZone.tensao_otima_20}`
	);
	const [adCritica20, setADCritica20] = useState(
		`${state.managementZone.tensao_critica_20}`
	);
	const [adOtima60, setADOtima60] = useState(
		`${state.managementZone.tensao_otima_60}`
	);
	const [adCritica60, setADCritica60] = useState(
		`${state.managementZone.tensao_critica_60}`
	);

	// Correção
	const [pluviometria, setPluviometria] = useState("");
	const [laminaAplicada, setLaminaAplicada] = useState("");
	const [etoCorrigido, setEtoCorrigido] = useState("");
	const [dataLeituraCorrecao, setDataLeituraCorrecao] = useState("");

	useEffect(() => {
		if (!getZMData.state.loading && getZMData.state.data.length > 0) {
			setDadosZM(getZMData.state.data[0]);
		}

		if (sistemaIrrigacao) {
			if (sistemaIrrigacao.nome.includes("Sequeiro")) {
				setEficienciaSistema("");
				setVazaoEmissor("");
				setEspacamentoEntreLinhas("");
				setEspacamentoEntreEmissores("");
				setProjecaoSaia("");
				setVazaoPivo("");
				setAreaPivo("");
				setTempoGiroPivo("");
			} else if (sistemaIrrigacao.nome.includes("Pivô")) {
				setVazaoEmissor("");
				setEspacamentoEntreLinhas("");
				setEspacamentoEntreEmissores("");
				setProjecaoSaia("");
			} else {
				setVazaoPivo("");
				setAreaPivo("");
				setTempoGiroPivo("");
			}
		}

		if (!sensorSolo) {
			setTipoDadoSolo(null);
			setArgila("");
			setAreia("");
			setSilte("");
			setMateriaOrganica("");
			setSolo(null);
		} else if (tipoDadoSolo) {
			if (tipoDadoSolo.id === 1) {
				setArgila("");
				setAreia("");
				setSilte("");
				setMateriaOrganica("");
			} else {
				setSolo(null);
			}
		}
	}, [
		sistemaIrrigacao,
		sensorSolo,
		tipoDadoSolo,
		getZMData.state.data,
		getZMData.state.loading,
	]);

	return (
		<div className="page">
			{getZMData.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div>
					<ul className="nav">
						<TabNavItem
							title="Dados da Zona de Manejo"
							id="tab1"
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						<TabNavItem
							title="Dados de Irrigação"
							id="tab2"
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						<TabNavItem
							title="Dados da Cultura e Solo"
							id="tab3"
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						<TabNavItem
							title="Configuração dos Alertas"
							id="tab4"
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						{getZMData.state.data.length > 0 ? (
							<TabNavItem
								title="Atualizar Dados Medidos"
								id="tab5"
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
						) : null}
					</ul>

					<div>
						<TabContent id="tab1" activeTab={activeTab}>
							<FirstTab
								state={state}
								allStates={{
									nome,
									setNome,
									organizacao,
									setOrganizacao,
									gateway,
									setGateway,
								}}
								user={user}
							/>
						</TabContent>
						<TabContent id="tab2" activeTab={activeTab}>
							<SecondTab
								state={state}
								allStates={{
									sistemaIrrigacao,
									setSistemaIrrigacao,
									eficienciaSistema,
									setEficienciaSistema,
									vazaoEmissor,
									setVazaoEmissor,
									espacamentoEntreLinhas,
									setEspacamentoEntreLinhas,
									espacamentoEntreEmissores,
									setEspacamentoEntreEmissores,
									projecaoSaia,
									setProjecaoSaia,
									vazaoPivo,
									setVazaoPivo,
									areaPivo,
									setAreaPivo,
									tempoGiroPivo,
									setTempoGiroPivo,
								}}
							/>
						</TabContent>
						<TabContent id="tab3" activeTab={activeTab}>
							<ThirdTab
								state={state}
								allStates={{
									cultura,
									setCultura,
									dataPlantio,
									setDataPlantio,
									espacamentoLinhasCultura,
									setEspacamentoLinhasCultura,
									espacamentoEntrePlantas,
									setEspacamentoEntrePlantas,
									sensorSolo,
									setSensorSolo,
									tipoSensorSolo,
									setTipoSensorSolo,
									coletor,
									setColetor,
									solo,
									setSolo,
									tipoDadoSolo,
									setTipoDadoSolo,
									argila,
									setArgila,
									areia,
									setAreia,
									silte,
									setSilte,
									materiaOrganica,
									setMateriaOrganica,
									cc,
									setCC,
									pmp,
									setPMP,
									thetaS0a20,
									setThetaS0a20,
									thetaS20a40,
									setThetaS20a40,
									thetaS40a60,
									setThetaS40a60,
									thetaR0a20,
									setThetaR0a20,
									thetaR20a40,
									setThetaR20a40,
									thetaR40a60,
									setThetaR40a60,
									armHidricoEstimado,
									setArmHidricoEstimado,
									dataARMHidricoEstimado,
									setDataARMHidricoEstimado,
								}}
							/>
						</TabContent>
						<TabContent id="tab4" activeTab={activeTab}>
							<FourthTab
								allStates={{
									adOtima20,
									setADOtima20,
									adCritica20,
									setADCritica20,
									adOtima60,
									setADOtima60,
									adCritica60,
									setADCritica60,
								}}
							/>
						</TabContent>
						<TabContent id="tab5" activeTab={activeTab}>
							<FifthTab
								allStates={{
									pluviometria,
									setPluviometria,
									laminaAplicada,
									setLaminaAplicada,
									etoCorrigido,
									setEtoCorrigido,
									dataLeituraCorrecao,
									setDataLeituraCorrecao,
								}}
								dadosZM={dadosZM}
							/>
						</TabContent>
					</div>
					{activeTab === "tab1" ? (
						<div className="footer">
							<SaveDataButton
								data={{
									id: `${state.managementZone.id}`,
									nome: nome,
									organizacao: organizacao
										? organizacao.id
										: org_no_admin,
									concentrador: gateway ? gateway.id : null,
									coletor: coletor ? coletor.id : null,
									sensor_solo: `${sensorSolo ? "on" : null}`,
									tipo_sensor_solo: tipoSensorSolo
										? tipoSensorSolo.nome
										: null,
									dado_irriga: sistemaIrrigacao
										? sistemaIrrigacao.id
										: null,
									efici_sistema: +eficienciaSistema,
									vazao_emissor: +vazaoEmissor,
									vazao_sistema_pivo: +vazaoPivo,
									espa_linha: +espacamentoEntreLinhas,
									espa_emissores: +espacamentoEntreEmissores,
									proje_saia: +projecaoSaia,
									area_pivo: +areaPivo,
									tempo_zero_cem: +tempoGiroPivo,
									data_cultura: dataPlantio,
									cultura: cultura ? cultura.id : null,
									espa_linha_cultura:
										+espacamentoLinhasCultura,
									espa_planta_cultura:
										+espacamentoEntrePlantas,
									cc: +cc,
									pmp: +pmp,
									theta_s_0_20: +thetaS0a20,
									theta_s_20_40: +thetaS20a40,
									theta_s_40_60: +thetaS40a60,
									theta_r_0_20: +thetaR0a20,
									theta_r_20_40: +thetaR20a40,
									theta_r_40_60: +thetaR40a60,
									tensao_otima_60: +adOtima60,
									tensao_critica_60: +adCritica60,
									tensao_otima_20: +adOtima20,
									tensao_critica_20: +adCritica20,
									tipo_dado_solo: tipoDadoSolo
										? tipoDadoSolo.nome
										: null,
									id_solo_curva: solo ? solo.id : null,
									argila: +argila,
									areia: +areia,
									silte: +silte,
									materia_organica: +materiaOrganica,
									arm_hidrico_estimado: +armHidricoEstimado,
									data_arm_hidrico_estimado:
										dataARMHidricoEstimado,
								}}
								action={"put"}
								endpoint={`/zonas_manejo_api/${state.managementZone.id}`}
								returnTo={"/management-zones"}
							/>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}

const FirstTab = ({ state, allStates, user }) => {
	return (
		<div>
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={state.managementZone.id}
					readOnly
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Nome *</span>
				<input
					className="admin-input"
					type="text"
					name="tag"
					value={allStates.nome}
					onChange={(e) => allStates.setNome(e.target.value)}
				/>
			</div>

			{user.nivel.includes("Admin") ? (
				<div className="admin-container">
					<span className="admin-title">Organização *</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.organizationsNameList}
						selectedValue={allStates.organizacao}
						setSelectedValue={allStates.setOrganizacao}
						width={"305px"}
					/>
				</div>
			) : null}

			<div className="admin-container">
				<span className="admin-title">
					Estação PalmaFlex mais Próxima *
				</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.gatewaysNameList}
					selectedValue={allStates.gateway}
					setSelectedValue={allStates.setGateway}
					width={"305px"}
				/>
			</div>
		</div>
	);
};

const SecondTab = ({ state, allStates }) => {
	return (
		<div>
			<div className="admin-container">
				<span className="admin-title">Dados do Sistema</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Tipo de Irrigação *</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.irrigationNameList}
					selectedValue={allStates.sistemaIrrigacao}
					setSelectedValue={allStates.setSistemaIrrigacao}
					width={"305px"}
				/>
			</div>
			{allStates.sistemaIrrigacao &&
			(allStates.sistemaIrrigacao.nome === "Gotejamento" ||
				allStates.sistemaIrrigacao.nome === "Aspersão") ? (
				<div>
					<div className="admin-container">
						<em className="item-text">Eficiência do Sistema (%)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="eff-sist"
							value={allStates.eficienciaSistema}
							onChange={(e) =>
								allStates.setEficienciaSistema(e.target.value)
							}
						/>
					</div>
					<div className="admin-container">
						<em className="item-text">Vazão do emissor (L/h)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="vazao-emissor"
							value={allStates.vazaoEmissor}
							onChange={(e) =>
								allStates.setVazaoEmissor(e.target.value)
							}
						/>
					</div>
					<div className="admin-container">
						<em className="item-text">
							Espaçamento entre Linhas da Irrigação (m)
						</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="espacamento-linhas-irrigacao"
							value={allStates.espacamentoEntreLinhas}
							onChange={(e) =>
								allStates.setEspacamentoEntreLinhas(
									e.target.value
								)
							}
						/>
					</div>
					<div className="admin-container">
						<em className="item-text">
							Espaçamento entre Emissores (m)
						</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="espacamento-emissores"
							value={allStates.espacamentoEntreEmissores}
							onChange={(e) =>
								allStates.setEspacamentoEntreEmissores(
									e.target.value
								)
							}
						/>
					</div>
					<div className="admin-container">
						<em className="item-text">Projeção da Saia (m)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="projecao-saia"
							value={allStates.projecaoSaia}
							onChange={(e) =>
								allStates.setProjecaoSaia(e.target.value)
							}
						/>
					</div>
				</div>
			) : allStates.sistemaIrrigacao &&
			  allStates.sistemaIrrigacao.nome === "Pivô" ? (
				<div>
					<div className="admin-container">
						<em className="item-text">Eficiência do Sistema (%)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="eff-sist"
							value={allStates.eficienciaSistema}
							onChange={(e) =>
								allStates.setEficienciaSistema(e.target.value)
							}
						/>
					</div>

					<div className="admin-container">
						<em className="item-text">
							Vazão do Sistema (m<sup>3</sup>/h)
						</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="vazao-sistema-pivo"
							value={allStates.vazaoPivo}
							onChange={(e) =>
								allStates.setVazaoPivo(e.target.value)
							}
						/>
					</div>

					<div className="admin-container">
						<em className="item-text">Área do Pivô (ha)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="area-pivo"
							value={allStates.areaPivo}
							onChange={(e) =>
								allStates.setAreaPivo(e.target.value)
							}
						/>
					</div>

					<div className="admin-container">
						<em className="item-text">Tempo de giro a 100% (h)</em>
						<input
							className="admin-input"
							type="number"
							min={0}
							name="tempo-giro"
							value={allStates.tempoGiroPivo}
							onChange={(e) =>
								allStates.setTempoGiroPivo(e.target.value)
							}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

const ThirdTab = ({ state, allStates }) => {
	let ArmEstimado = () => {
		return (
			<div>
				<div className="admin-container">
					<span className="admin-title">
						Umidade Estimada do Solo
					</span>
				</div>

				<SelectDropdown
					placeHolder="Selecionar..."
					options={estimatedWaterStorageItems}
					selectedValue={allStates.armHidricoEstimado}
					setSelectedValue={allStates.setArmHidricoEstimado}
					width={"305px"}
				/>

				<div className="admin-container">
					<span className="admin-title">Data da Estimação</span>
				</div>
				<input
					className="admin-input"
					type="date"
					name="data-estimacao"
					value={allStates.dataARMHidricoEstimado}
					onChange={(e) =>
						allStates.setDataARMHidricoEstimado(e.target.value)
					}
				/>
			</div>
		);
	};

	return (
		<div style={{ marginBottom: "100px" }}>
			<div className="admin-container">
				<span className="admin-title">Dados sobre a Cultura</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Cultura *</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.cultivationNameList}
					selectedValue={allStates.cultura}
					setSelectedValue={allStates.setCultura}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Data Aproximada do Plantio *</em>
				<input
					className="admin-input"
					type="date"
					name="data-plantio"
					value={allStates.dataPlantio}
					onChange={(e) => allStates.setDataPlantio(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">
					Espaçamento entre linhas da cultura (m)
				</em>
				<input
					className="admin-input"
					type="number"
					min={0}
					name="espacamento-linhas-cultura"
					value={allStates.espacamentoLinhasCultura}
					onChange={(e) =>
						allStates.setEspacamentoLinhasCultura(e.target.value)
					}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Espaçamento entre plantas (m)</em>
				<input
					className="admin-input"
					type="number"
					min={0}
					name="espacamento-entre-plantas"
					value={allStates.espacamentoEntrePlantas}
					onChange={(e) =>
						allStates.setEspacamentoEntrePlantas(e.target.value)
					}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Dados sobre o Solo</span>
			</div>

			<div className="checkbox-container">
				<input
					className="checkbox-style"
					type="checkbox"
					checked={allStates.sensorSolo}
					onChange={(e) => allStates.setSensorSolo(e.target.checked)}
				/>
				<em className="checkbox-text">Sensores de Solo</em>
			</div>

			{allStates.sensorSolo ? (
				<div style={{ margin: "-10px 0px 20px 0px" }}>
					<div className="admin-container">
						<span className="admin-title">Coletor</span>
					</div>

					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.dataloggersNameList}
						selectedValue={allStates.coletor}
						setSelectedValue={allStates.setColetor}
						width={"305px"}
					/>

					<div className="admin-container">
						<span className="admin-title">
							Tipo do Sensor de Solo
						</span>
					</div>

					<SelectDropdown
						placeHolder="Selecionar..."
						options={soilSensorType}
						selectedValue={allStates.tipoSensorSolo}
						setSelectedValue={allStates.setTipoSensorSolo}
						width={"305px"}
					/>

					{allStates.tipoSensorSolo &&
					allStates.tipoSensorSolo.nome === "Tensiômetro" ? (
						<div>
							<div className="admin-container">
								<em className="item-text">
									Tipo de Dados do Solo *
								</em>
								<SelectDropdown
									placeHolder="Selecionar..."
									options={soilDataType}
									selectedValue={allStates.tipoDadoSolo}
									setSelectedValue={allStates.setTipoDadoSolo}
									width={"305px"}
								/>
							</div>
							{!allStates.tipoDadoSolo ? null : allStates.tipoDadoSolo.nome.includes(
									"Retenção"
							  ) ? (
								<div>
									<div className="admin-container">
										<em className="item-text">
											Tipo de Solo *
										</em>
										<SelectDropdown
											placeHolder="Selecionar..."
											options={state.soilNameList}
											selectedValue={allStates.solo}
											setSelectedValue={allStates.setSolo}
											width={"305px"}
										/>
									</div>
								</div>
							) : (
								<div>
									<div className="admin-container">
										<em className="item-text">
											Argila (%)
										</em>
										<input
											className="admin-input"
											type="number"
											min={0}
											name="argila"
											value={allStates.argila}
											onChange={(e) =>
												allStates.setArgila(
													e.target.value
												)
											}
										/>
									</div>

									<div className="admin-container">
										<em className="item-text">Areia (%)</em>
										<input
											className="admin-input"
											type="number"
											min={0}
											name="areia"
											value={allStates.areia}
											onChange={(e) =>
												allStates.setAreia(
													e.target.value
												)
											}
										/>
									</div>

									<div className="admin-container">
										<em className="item-text">Silte (%)</em>
										<input
											className="admin-input"
											type="number"
											min={0}
											name="silte"
											value={allStates.silte}
											onChange={(e) =>
												allStates.setSilte(
													e.target.value
												)
											}
										/>
									</div>

									<div className="admin-container">
										<em className="item-text">
											Matéria Orgânica (%)
										</em>
										<input
											className="admin-input"
											type="number"
											min={0}
											name="materia-organizaca"
											value={allStates.materiaOrganica}
											onChange={(e) =>
												allStates.setMateriaOrganica(
													e.target.value
												)
											}
										/>
									</div>
								</div>
							)}
							<div className="admin-container">
								<em className="item-text">
									Capacidade de Campo (kPa)
								</em>
								<input
									className="admin-input"
									type="number"
									min={0}
									name="cc"
									value={allStates.cc}
									onChange={(e) =>
										allStates.setCC(e.target.value)
									}
								/>
							</div>

							<div className="admin-container">
								<em className="item-text">
									Ponto de Murcha Permanente (kPa)
								</em>
								<input
									className="admin-input"
									type="number"
									min={0}
									name="pmp"
									value={allStates.pmp}
									onChange={(e) =>
										allStates.setPMP(e.target.value)
									}
								/>
							</div>
						</div>
					) : (
						<div>
							<div>
								<div className="admin-container">
									<span className="admin-title">
										Capacidade de Campo
									</span>
								</div>

								<div className="admin-container">
									<em className="item-text">
										De 0cm a 20cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-s-0-20"
										value={allStates.thetaS0a20}
										onChange={(e) =>
											allStates.setThetaS0a20(
												e.target.value
											)
										}
									/>
								</div>
								<div className="admin-container">
									<em className="item-text">
										De 20cm a 40cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-s-20-40"
										value={allStates.thetaS20a40}
										onChange={(e) =>
											allStates.setThetaS20a40(
												e.target.value
											)
										}
									/>
								</div>
								<div className="admin-container">
									<em className="item-text">
										De 40cm a 60cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-s-40-60"
										value={allStates.thetaS40a60}
										onChange={(e) =>
											allStates.setThetaS40a60(
												e.target.value
											)
										}
									/>
								</div>

								<div className="admin-container">
									<span className="admin-title">
										Armazenamento Crítico
									</span>
								</div>

								<div className="admin-container">
									<em className="item-text">
										De 0cm a 20cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-r-0-20"
										value={allStates.thetaR0a20}
										onChange={(e) =>
											allStates.setThetaR0a20(
												e.target.value
											)
										}
									/>
								</div>
								<div className="admin-container">
									<em className="item-text">
										De 20cm a 40cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-r-20-40"
										value={allStates.thetaR20a40}
										onChange={(e) =>
											allStates.setThetaR20a40(
												e.target.value
											)
										}
									/>
								</div>
								<div className="admin-container">
									<em className="item-text">
										De 40cm a 60cm (%)
									</em>
									<input
										className="admin-input"
										type="number"
										min={0}
										name="theta-s-40-60"
										value={allStates.thetaR40a60}
										onChange={(e) =>
											allStates.setThetaR40a60(
												e.target.value
											)
										}
									/>
								</div>
							</div>
							<ArmEstimado />
						</div>
					)}
				</div>
			) : (
				<div>
					<div>
						<div className="admin-container">
							<span className="admin-title">
								Capacidade de Campo
							</span>
						</div>

						<div className="admin-container">
							<em className="item-text">De 0cm a 20cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-s-0-20"
								value={allStates.thetaS0a20}
								onChange={(e) =>
									allStates.setThetaS0a20(e.target.value)
								}
							/>
						</div>
						<div className="admin-container">
							<em className="item-text">De 20cm a 40cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-s-20-40"
								value={allStates.thetaS20a40}
								onChange={(e) =>
									allStates.setThetaS20a40(e.target.value)
								}
							/>
						</div>
						<div className="admin-container">
							<em className="item-text">De 40cm a 60cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-s-40-60"
								value={allStates.thetaS40a60}
								onChange={(e) =>
									allStates.setThetaS40a60(e.target.value)
								}
							/>
						</div>

						<div className="admin-container">
							<span className="admin-title">
								Armazenamento Crítico
							</span>
						</div>

						<div className="admin-container">
							<em className="item-text">De 0cm a 20cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-r-0-20"
								value={allStates.thetaR0a20}
								onChange={(e) =>
									allStates.setThetaR0a20(e.target.value)
								}
							/>
						</div>
						<div className="admin-container">
							<em className="item-text">De 20cm a 40cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-r-20-40"
								value={allStates.thetaR20a40}
								onChange={(e) =>
									allStates.setThetaR20a40(e.target.value)
								}
							/>
						</div>
						<div className="admin-container">
							<em className="item-text">De 40cm a 60cm (%)</em>
							<input
								className="admin-input"
								type="number"
								min={0}
								name="theta-s-40-60"
								value={allStates.thetaR40a60}
								onChange={(e) =>
									allStates.setThetaR40a60(e.target.value)
								}
							/>
						</div>
					</div>
					<ArmEstimado />
				</div>
			)}
		</div>
	);
};

const FourthTab = ({ allStates }) => {
	return (
		<div>
			<div className="admin-container">
				<span className="admin-title">
					Alerta de Água Disponível no Solo até 20cm
				</span>
				<div className="admin-container">
					<em className="item-text">Fim da Tensão Ótima (%)</em>
					<input
						className="admin-input"
						type="number"
						min={0}
						name="tensao-otima-20"
						value={allStates.adOtima20}
						onChange={(e) => allStates.setADOtima20(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Início da Tensão Crítica (%)</em>
					<input
						className="admin-input"
						type="number"
						min={0}
						name="tensao-critica-20"
						value={allStates.adCritica20}
						onChange={(e) =>
							allStates.setADCritica20(e.target.value)
						}
					/>
				</div>
			</div>

			<div className="admin-container">
				<span className="admin-title">
					Alerta de Água Disponível no Solo de 0 a 60cm
				</span>
				<span></span>
				<div className="admin-container">
					<em className="item-text">Fim da Tensão Ótima (%)</em>
					<input
						className="admin-input"
						type="number"
						min={0}
						name="tensao-otima-60"
						value={allStates.adOtima60}
						onChange={(e) => allStates.setADOtima60(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Início da Tensão Crítica (%)</em>
					<input
						className="admin-input"
						type="number"
						min={0}
						name="tensao-critica-60"
						value={allStates.adCritica60}
						onChange={(e) =>
							allStates.setADCritica60(e.target.value)
						}
					/>
				</div>
			</div>
		</div>
	);
};

const FifthTab = ({ allStates, dadosZM }) => {
	return (
		<div>
			<div className="admin-container">
				<em className="item-text">Pluviometria Medida (mm)</em>
				<input
					className="admin-input"
					type="number"
					min={0}
					name="pluviometria-medida"
					value={allStates.pluviometria}
					onChange={(e) => allStates.setPluviometria(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Lâmina Aplicada (mm)</em>
				<input
					className="admin-input"
					type="number"
					min={0}
					name="lamina-medida"
					value={allStates.laminaAplicada}
					onChange={(e) =>
						allStates.setLaminaAplicada(e.target.value)
					}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">ETo (mm)</em>
				<input
					className="admin-input"
					type="number"
					min={0}
					name="eto-corrigido"
					value={allStates.etoCorrigido}
					onChange={(e) => allStates.setEtoCorrigido(e.target.value)}
				/>
			</div>

			<div className="admin-container" style={{ marginBottom: "50px" }}>
				<em className="item-text">Data da Leitura</em>
				<input
					className="admin-input"
					type="date"
					name="data-leitura-lamina"
					value={allStates.dataLaminaAplicada}
					onChange={(e) =>
						allStates.setDataLaminaAplicada(e.target.value)
					}
				/>
			</div>
			<SaveDataButton
				data={{
					id: +`${dadosZM.id}`,
					id_zm: +`${dadosZM.id_zm}`,
					pluv_corr: +allStates.pluviometria,
					lamina_app: +allStates.laminaAplicada,
					eto_corr: +allStates.etoCorrigido,
					data_leitura: allStates.dataLaminaAplicada,
					z: +`${dadosZM.z}`,
					kc: +`${dadosZM.kc}`,
					cad: +`${dadosZM.cad}`,
					arm: +`${dadosZM.arm}`,
					lb: +`${dadosZM.lb}`,
					ti: +`${dadosZM.ti}`,
					r_perc: +`${dadosZM.r_perc}`,
					etc: +`${dadosZM.etc}`,
					fase_fenologica: `${dadosZM.fase_fenologica}`,
					dias_decorridos: +`${dadosZM.dias_decorridos}`,
					ad20: +`${dadosZM.ad20}`,
					ad60: +`${dadosZM.ad60}`,
					max_agua_raiz: +`${dadosZM.max_agua_raiz}`,
					min_agua_raiz: +`${dadosZM.min_agua_raiz}`,
				}}
				action={"put"}
				endpoint={`/dados_zonas_manejo_api/${dadosZM.id}`}
				returnTo={`/management-zones`}
			/>
		</div>
	);
};
