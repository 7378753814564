import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { roleItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

import "../../../styles/admin-styles.css";

export default function OrganizationCreate() {
	const { state } = useLocation();
	const [nome, setNome] = useState("");
	const [papel, setPapel] = useState(null);
	const [representante, setRepresentante] = useState(null);

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Papel</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={roleItems}
					selectedValue={papel}
					setSelectedValue={setPapel}
					width={"305px"}
				/>
			</div>
			{papel &&
			(papel.nome === "Somente Visualização" ||
				papel.nome === "Cliente") ? (
				<div className="admin-container">
					<span className="admin-title">Representante</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.list}
						selectedValue={representante}
						setSelectedValue={setRepresentante}
						width={"305px"}
					/>
				</div>
			) : null}
			<div className="footer">
				<SaveDataButton
					data={{
						nome: `${nome}`,
						representante: `${
							representante &&
							papel &&
							(papel.nome === "Somente Visualização" ||
								papel.nome === "Cliente")
								? representante.id
								: ""
						}`,
						nivel: `${papel ? papel.nome : ""}`,
					}}
					action={"post"}
					endpoint={"/organizacao_api"}
					returnTo={`/organizations`}
				/>
			</div>
		</div>
	);
}
