import React from "react";

import "./dataTableCustom.css";
import { RiFileDownloadLine } from "react-icons/ri";

export default function ExportButton({ onExport }) {
	return (
		<div>
			<button
				className="data-table-button-custom"
				onClick={(e) => onExport(e.target.value)}
			>
				<RiFileDownloadLine size={"16px"} className="icon-style" />
				Exportar
			</button>
		</div>
	);
}
