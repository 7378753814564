import soilInfographicImage from "../../../../../assets/soil-infographic.png";
import "../../../../../styles/panel-styles.css";

export default function AvailableWaterPanel({ data, zonaManejo }) {
	let currentAvailableWater20 = "...";
	let currentAvailableWater60 = "...";
	let colorAW20Panel = "gray",
		colorAW60Panel = "gray";

	if (!isNaN(data.ad20) || data.ad20) {
		currentAvailableWater20 = data.ad20.toFixed(1);
		if (currentAvailableWater20 >= zonaManejo.tensao_otima_20)
			colorAW20Panel = "rgb(68, 114, 196)";
		else if (currentAvailableWater20 < zonaManejo.tensao_critica_20)
			colorAW20Panel = "rgb(206, 100, 100)";
		else colorAW20Panel = "rgb(196, 196, 69)";
	}
	if (!isNaN(data.ad60) || data.ad60) {
		currentAvailableWater60 = data.ad60.toFixed(1);

		if (currentAvailableWater60 >= zonaManejo.tensao_otima_60)
			colorAW60Panel = "rgb(68, 114, 196)";
		else if (currentAvailableWater60 < zonaManejo.tensao_critica_60)
			colorAW60Panel = "rgb(206, 100, 100)";
		else colorAW60Panel = "rgb(196, 196, 69)";
	}

	return (
		<div className="alerts-container" style={{ width: "270px" }}>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">
					Água Disponível no Solo
				</span>
			</div>
			<div className="alerts-panel-content">
				<div className="ad-panel-content-container">
					<div
						className="ad-panel-values-container"
						style={{
							marginRight: "auto",
							backgroundColor: `${colorAW60Panel}`,
						}}
					>
						<span className="ad-panel-values-text">
							{currentAvailableWater60} mm
						</span>
					</div>
					<div className="ad-panel-20-value-container ">
						<div
							className="ad-panel-values-container"
							style={{
								marginLeft: "auto",
								backgroundColor: `${colorAW20Panel}`,
							}}
						>
							<h4 className="ad-panel-values-text">
								{currentAvailableWater20} mm
							</h4>
						</div>
					</div>
				</div>
				<div className="ad-panel-content-container">
					<div className="ad-panel-content-container-align">
						<div className="ad-panel-first-column-size">
							<div className="ad-panel-first-column-color">
								<div className="ad-panel-second-column-color">
									<div
										style={{
											backgroundColor: `${colorAW60Panel}`,
											borderRadius: 25,
											height: `${
												currentAvailableWater60 / 6
											}%`,
											width: "100%",
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
					<img src={soilInfographicImage} height={"200px"} alt="" />
					<div className="ad-panel-content-container-align">
						<div className="ad-panel-first-column-size">
							<div
								className="ad-panel-first-column-color"
								style={{
									height: "30%",
								}}
							>
								<div className="ad-panel-second-column-color">
									<div
										style={{
											backgroundColor: `${colorAW20Panel}`,
											borderRadius: 25,
											height: `${
												currentAvailableWater20 / 2
											}%`,
											width: "100%",
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
