import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/api";
import AuthService from "../../services/auth";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";
import { FiSave } from "react-icons/fi";

export default function SaveDataButton({ data, action, endpoint, returnTo }) {
	const Navigate = useNavigate();
	const user = AuthService.getCurrentUser();
	const [isLoading, setIsloading] = useState(false);
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const handleSaveData = async (e) => {
		e.preventDefault();

		setIsloading(true);

		let config = {
			method: `${action}`,
			url: `${BASE_URL}${endpoint}`,
			headers: {
				Authorization: `Bearer ${user.access_token}`,
				"Content-Type": "application/json",
			},
			data: data,
		};

		try {
			const resp = await axios.request(config);
			setResponse(resp);
			setShowAlert(true);
		} catch (e) {
			setErrorStatus(true);
			setError(e.message);
			setShowAlert(true);
		} finally {
			setIsloading(false);
		}

		setTimeout(() => {
			setShowAlert(false);
			Navigate(returnTo);
		}, 1000);
	};

	return (
		<div>
			<button
				className="data-table-button-custom"
				onClick={handleSaveData}
			>
				<FiSave size={"16px"} className="icon-style" />
				Salvar
			</button>
			{isLoading ? (
				<AlertComponent message={"Carregando..."} code={"LOADING"} />
			) : null}
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{action === "post" && response.status === 201 ? (
				<AlertComponent
					message={"Novo item adicionado com sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
			{action === "put" && response.status === 200 && showAlert ? (
				<AlertComponent
					message={"Atualização feita com sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
		</div>
	);
}
