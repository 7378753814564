import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";

export default function SoilTypeList() {
	let soilTypeList = useGet("solo_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!soilTypeList.state.loading) {
			setData(soilTypeList.state.data["Solos"]);
			setRecords(data);
		}
	}, [soilTypeList.state.loading, soilTypeList.state.data, data]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			name: "Θr (20cm; 40cm; 60cm)",
			selector: (row) =>
				row.theta_r_0_20 +
				"; " +
				row.theta_r_20_40 +
				"; " +
				row.theta_r_40_60,
		},
		{
			name: "Θs (20cm; 40cm; 60cm)",
			selector: (row) =>
				row.theta_s_0_20 +
				"; " +
				row.theta_s_20_40 +
				"; " +
				row.theta_s_40_60,
		},
		{
			name: "α (20cm; 40cm; 60cm)",
			selector: (row) =>
				row.alpha_0_20 +
				"; " +
				row.alpha_20_40 +
				"; " +
				row.alpha_40_60,
		},
		{
			name: "n (20cm; 40cm; 60cm)",
			selector: (row) =>
				row.n_solo_0_20 +
				"; " +
				row.n_solo_20_40 +
				"; " +
				row.n_solo_40_60,
		},
		{
			name: "m (20cm; 40cm; 60cm)",
			selector: (row) =>
				row.m_solo_0_20 +
				"; " +
				row.m_solo_20_40 +
				"; " +
				row.m_solo_40_60,
		},
	];

	const openSoilDetail = (soilData) => {
		Navigate(`/soils/${soilData.id}`, {
			state: {
				soilData,
			},
		});
	};

	const Export = ({ onExport }) => <ExportButton onExport={onExport} />;

	return (
		<div className="page">
			{soilTypeList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<Export onExport={() => downloadCSV(data, "solos")} />
						<AddButton goTo={"/soils/add-new-soil"} />
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Tipo de Solo encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(soilData) => openSoilDetail(soilData)}
					/>
				</div>
			)}
		</div>
	);
}
