import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import AddItemButton from "../../data-table-custom/add-item-button";

import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";

export default function CultivationTypeList() {
	let cultivationsList = useGet("cultura_api");

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!cultivationsList.state.loading) {
			setData(cultivationsList.state.data["Cultura"]);
			setRecords(data);
		}
	}, [cultivationsList.state.loading, cultivationsList.state.data, data]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			cell: (row) => (
				<AddItemButton
					data={row}
					text={"Fases Fenológicas"}
					goTo={`/cultivations-list/${row.id}/phenological-stages`}
				/>
			),
		},
	];

	const openCultivationsDetail = (cultivationData) => {
		Navigate(`/cultivations-list/${cultivationData.id}`, {
			state: {
				cultivationData,
			},
		});
	};

	return (
		<div className="page">
			{cultivationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<AddButton
							goTo={"/cultivations-list/add-new-cultivation"}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Cultura encontrada."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(cultivationData) =>
							openCultivationsDetail(cultivationData)
						}
					/>
				</div>
			)}
		</div>
	);
}
