import { useState } from "react";
import { useLocation } from "react-router-dom";

import SaveDataButton from "../../../data-table-custom/save-data-button";

import "../../../../styles/admin-styles.css";

export default function PhenologicalStagesCreate() {
	const { state } = useLocation();

	const [nome, setNome] = useState("");
	const [ciclo, setCiclo] = useState("");
	const [profundidadeRaiz, setProfundidadeRaiz] = useState("");
	const [kc, setKc] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Ciclo (dias)</span>
				<input
					className="admin-input"
					type="number"
					name="ciclo"
					value={ciclo}
					onChange={(e) => setCiclo(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Profundidade da Raiz (cm)</span>
				<input
					className="admin-input"
					type="number"
					name="profundidade-raiz"
					value={profundidadeRaiz}
					onChange={(e) => setProfundidadeRaiz(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">kc</span>
				<input
					className="admin-input"
					type="number"
					name="kc"
					value={kc}
					onChange={(e) => setKc(e.target.value)}
				/>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						cultura_feno_id: `${+state.list}`,
						nome: `${nome}`,
						ciclo: `${+ciclo}`,
						profundidade: `${+profundidadeRaiz}`,
						kc: `${+kc}`,
					}}
					action={"post"}
					endpoint={"/fenologica_api"}
					returnTo={`/cultivations-list/${state.list}/phenological-stages`}
				/>
			</div>
		</div>
	);
}
