import React from "react";

import THDataPanel from "../panel/th-data-panel";
import LastDataPanel from "../panel/last-data-panel";
import HourlyDataTablePanel from "../panel/hourly-data-table-panel/hourly-data-table-panel";

import "../../../styles/dashboard-styles.css";

export default function HourlyData({ gateway }) {
	return (
		<div>
			<div className="show-data-container">
				<THDataPanel gateway={gateway} />
				<LastDataPanel gateway={gateway} />
			</div>
			<div className="show-data-big-container">
				<HourlyDataTablePanel gateway={gateway} />
			</div>
		</div>
	);
}
