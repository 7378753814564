import React from "react";

import {
	MdOutlineToday,
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
	MdOutlineBalance,
} from "react-icons/md";

import { GiPlantWatering } from "react-icons/gi";

import { RxCounterClockwiseClock } from "react-icons/rx";

export default function DashboardButtons({
	buttonType,
	buttonNumber,
	setButtonNumber,
	buttonTitle,
	gateway,
}) {
	let Icon = () => {
		switch (buttonType) {
			case "horario":
				return (
					<RxCounterClockwiseClock
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "diario":
				return (
					<MdOutlineToday
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "balanco-hidrico":
				return (
					<MdOutlineBalance
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "pulverizacao":
				return (
					<GiPlantWatering
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<button
			className="dropdown-button-style"
			onClick={() => setButtonNumber(!buttonNumber)}
			disabled={!gateway ? true : false}
		>
			<Icon />
			<h3 className="button-title">{buttonTitle}</h3>
			<div className="dropdown-button-arrow">
				{buttonNumber ? (
					<MdOutlineKeyboardArrowUp size={"20px"} color={"black"} />
				) : (
					<MdOutlineKeyboardArrowDown size={"20px"} color={"black"} />
				)}
			</div>
		</button>
	);
}
