import React, { useEffect, useState } from "react";
import { useGet } from "../../../reducers/useGet";
import ReactLoading from "react-loading";

import ManagementZonesHeader from "../management-zones-header";
import CultivationDataPanel from "../panel/cultivation-data-panel";
import AlertsPanel from "../panel/alerts-panel";
import HydrologicalBalancePanel from "../panel/hydrological-balance-panel";
import WaterStoragePanel from "../panel/water-storage-panel";
import HydrologicalBalanceTablePanel from "../panel/hydrological-balance-table-panel";
import NoManagementZoneMessage from "../messages-panel/no-management-zone-message";
import sortArray from "../../../lib/sort-array";

import "../../../styles/dashboard-styles.css";

export default function HydrologicalBalanceData({ gateway }) {
	let getZM = useGet(
		`/zona_manejo_consulta?zona_manejo_gateway=${gateway.id}`
	);

	const [zonaManejo, setZonaManejo] = useState(null);
	const [listaZonaManejo, setListaZOnaManejo] = useState([]);

	useEffect(() => {
		if (!getZM.state.loading) {
			setListaZOnaManejo(sortArray(getZM.state.data));
		}
	}, [getZM.state.loading, getZM.state.data]);

	return (
		<div>
			{getZM.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : listaZonaManejo.length > 0 ? (
				<div>
					<ManagementZonesHeader
						listaZonaManejo={listaZonaManejo}
						zonaManejo={zonaManejo}
						setZonaManejo={setZonaManejo}
					/>
					{zonaManejo ? (
						<div>
							<div className="show-data-container">
								<CultivationDataPanel zonaManejo={zonaManejo} />
								<AlertsPanel zonaManejo={zonaManejo} />
							</div>

							<div className="show-data-container">
								<WaterStoragePanel zonaManejo={zonaManejo} />
								<HydrologicalBalancePanel
									zonaManejo={zonaManejo}
									gateway={gateway}
								/>
							</div>
							<div className="show-data-big-container">
								<HydrologicalBalanceTablePanel
									zonaManejo={zonaManejo}
									gateway={gateway}
								/>
							</div>
						</div>
					) : (
						<div
							style={{
								height: "5px",
							}}
						/>
					)}
				</div>
			) : (
				<NoManagementZoneMessage />
			)}
		</div>
	);
}
