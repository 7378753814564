import React, { useState, useEffect } from "react";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import moment from "moment";

import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";

import pencilIcon from "../../../../assets/icons/pencil-icon.png";
import cultivationIcon from "../../../../assets/icons/cultivation-icon.png";
import rulerIcon from "../../../../assets/icons/ruler-icon.png";
import calenderDayIcon from "../../../../assets/icons/calendar-day-icon.png";
import calenderDaysIcon from "../../../../assets/icons/calendar-days-icon.png";

import "../../../../styles/panel-styles.css";

export default function CultivationDataPanel({ zonaManejo }) {
	const [cultivationsName, setCultivationsName] = useState("");
	const [data, setData] = useState([]);

	let getCultivationData = useGet(`cultura_api/${zonaManejo.cultura}`);
	let getZMData = useGet(
		`dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&ultimo_dado=1`
	);

	useEffect(() => {
		if (
			!getCultivationData.state.loading &&
			!getZMData.state.loading &&
			getZMData.state.data.length > 0
		) {
			setCultivationsName(getCultivationData.state.data.nome);
			setData(getZMData.state.data[0]);
		}
	}, [
		getCultivationData.state.loading,
		getCultivationData.state.data,
		getZMData.state.loading,
		getZMData.state.data,
	]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Dados da Cultura</em>
			</div>

			<div className="panel-content-margin">
				<div>
					{getCultivationData.state.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "300px",
							}}
						>
							<ReactLoading
								type="spinningBubbles"
								color="var(--main-color)"
								width="50px"
								height="50px"
							/>
						</div>
					) : getZMData.state.data.length > 0 ? (
						<div>
							<div className="item-data-container">
								<img
									src={pencilIcon}
									height={"20px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Cultura: &nbsp;
								</p>
								{cultivationsName ? (
									<span className="last-value-text">
										{cultivationsName}
									</span>
								) : (
									<span className="last-value-text">...</span>
								)}
							</div>

							<div className="item-data-container">
								<img
									src={cultivationIcon}
									height={"20px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Fase Fenológica Atual: &nbsp;
								</p>
								{data.fase_fenologica ? (
									<span className="last-value-text">
										{data.fase_fenologica}
									</span>
								) : (
									<span className="last-value-text">...</span>
								)}
							</div>
							<div className="item-data-container">
								<img
									src={rulerIcon}
									height={"20px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Profundidade da Raiz Atual: &nbsp;
								</p>
								{!isNaN(data.z) ? (
									<span className="last-value-text">
										{data.z} cm
									</span>
								) : (
									<span className="last-value-text">
										... cm
									</span>
								)}
							</div>
							<div className="item-data-container">
								<img
									src={calenderDayIcon}
									height={"20px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Data Aproximada do Plantio: &nbsp;
								</p>
								{zonaManejo.data_cultura ? (
									<span className="last-value-text">
										{moment(zonaManejo.data_cultura).format(
											"DD/MM/YYYY"
										)}
									</span>
								) : (
									<span className="last-value-text">...</span>
								)}
							</div>
							<div className="item-data-container">
								<img
									src={calenderDaysIcon}
									height={"20px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								<p className="paragraph-style">
									Dias Decorridos: &nbsp;
								</p>
								{!isNaN(data.dias_decorridos) ? (
									<span className="last-value-text">
										{data.dias_decorridos}
									</span>
								) : (
									<span className="last-value-text">...</span>
								)}
							</div>
						</div>
					) : (
						<NoDataAvailableMessage />
					)}
				</div>
			</div>
		</div>
	);
}
